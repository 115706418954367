import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { AppComponent } from '../../../../../app.component';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { SupabaseService } from '../../../../../core/auth/supabase.service';
import { CalendarService } from '../calendar.service';
import { AbsenceTypeService } from '../../absence-type/absence-type.service';
import { Subject, takeUntil } from 'rxjs';
import { STATICS } from '../../../../../core/config/static/static';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CreateEventComponent } from '../create-event/create-event.component';
import { UpdateEventComponent } from '../update-event/update-event.component';
import { RecordsListComponent } from 'app/core/base-classes/records-list-component';
import { DepartmentService } from '../../departments/departments.service';
@Component({
  selector: 'app-team-calendar-resource',
  templateUrl: './team-calendar-resource.component.html',
  styleUrls: ['./team-calendar-resource.component.scss'],
})
export class TeamCalendarResourceComponent extends RecordsListComponent<any> {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  calendarVisible = true;
  eventsArray = [];
  resourcesArray = [];
  absenceTypes = [];
  recordFilter: any;
  drawerOpened = true;
  selectedUser: any;
  drawerMode: 'over' | 'side' = 'side';

  calendarOptions = {
    height: 655,
    plugins: [interactionPlugin, resourceTimelinePlugin, timeGridPlugin],
    initialView: 'resourceTimelineMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
    },
    views: {
      resourceTimelineMonth: {
        slotLabelFormat: [
          { weekday: 'short', day: '2-digit' }, // lower level of text
        ],
      },
    },
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    resourceAreaHeaderContent: 'Users',
    refetchResourcesOnNavigate: true,
    buttonText: {
      today: 'Today',
      day: 'Day',
      week: 'Week',
      month: 'Month',
    },
    resources: [],
    // resourceColumns: [
    //   {
    //     labelText: 'Name',
    //     field: 'email',
    //   },
    // ],
    events: [],
    editable: false,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: true,
    eventStartEditable: false,
    droppable: false,
    weekends: true,
    firstDay: 1,
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.handleMonthChange.bind(this),
  };

  currentUser: any;
  userId: any;
  constructor(
    private matDialog: MatDialog,
    @Inject(AppComponent) private appComponent: AppComponent,
    private _supabaseService: SupabaseService,
    private calendarService: CalendarService,
    private absenceTypeService: AbsenceTypeService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _departmentService: DepartmentService
  ) {
    super(appComponent);
    this.recordFilter = {};
    this._supabaseService.getCurrentUser$.pipe().subscribe((user: any) => {
      this.currentUser = user;
      this.resourcesArray = [];
      this.showLoader();
      this.calendarService.departmentFilter(this.currentUser.userId).subscribe(
        (data) => {
          if (data.length > 0) {
            // Loop through the filterDepartmentOption array and store IDs
            const departmentIds = [];
            for (const department of data) {
              departmentIds.push(department._id);
            }
            this.calendarService.teamList(departmentIds, this.currentUser.positionId).subscribe(
              (data) => {
                const userIds = [];
                if (data?.result.length > 0) {
                  for (let index = 0; index < data?.result.length; index++) {
                    if (this.currentUser?.userId !== data?.result[index].userId) {
                      this.resourcesArray.push({
                        id: data?.result[index].userId,
                        title: data?.result[index].firstName + ' ' + data?.result[index].lastName + ' (' + data?.result[index].email + ' )',
                        email: data?.result[index].email,
                        firstName: data?.result[index].firstName,
                        lastName: data?.result[index].lastName,
                        eventAllow: true,
                      });
                      userIds.push(data?.result[index].userId);
                    }
                  }
                  this.recordFilter['userId'] = userIds;

                  this.absenceTypeService.filterList().subscribe(
                    (data) => {
                      this.absenceTypes = data;
                      //this.getRecordsList();
                    },
                    (err) => {
                      this.hideLoader();
                      this.showErrorToast(`Error in record updating ${err.error.message}`);
                    }
                  );
                } else {
                  this.showWarningToast(`You have no team members`);
                }
              },
              (err) => {
                this.hideLoader();
                this.showWarningToast(`Error in fetching record ${err.error.message}`);
              }
            );
          } else {
            this.hideLoader();
          }
        },
        (err) => {
          this.hideLoader();
          this.showWarningToast(`Error in fetching record ${err.error.message}`);
        }
      );
    });
  }

  public getRecordsList() {
    // For Get Event
    this.showLoader();
    this.calendarService.getAll(this.recordFilter).subscribe(
      () => {
        this.calendarService.absence$.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
          this.recordsList = response;
          this.totalCount = this.calendarService.absenceCount$;
          this.eventsArray = [];
          if (this.recordsList.length > 0) {
            for (let index = 0; index < this.recordsList.length; index++) {
              let endDate = new Date(this.recordsList[index].endDate);
              if (this.recordsList[index].isFullDay) {
                endDate = new Date(endDate.setUTCHours(23, 59, 59, 999));
              }
              this.eventsArray.push({
                id: this.recordsList[index]._id,
                userId: this.recordsList[index].userId,
                createdBy: this.recordsList[index].createdBy,
                status: this.recordsList[index].absenceStatus,
                allDay: this.recordsList[index].isFullDay,
                title: this.recordsList[index].absenceTypeName,
                titleId: this.recordsList[index].absenceTypeId,
                start: this.recordsList[index].startDate,
                end: endDate,
                actualEndDate: this.recordsList[index].endDate,
                backgroundColor: STATICS.ABSENCE_STATUS.filter((status) => status.status === this.recordsList[index].absenceStatus)[0].color,
                description: this.recordsList[index].reason,
                resourceIds: [this.recordsList[index].userId],
                rejectedReason: this.recordsList[index].rejectedReason,
                reason: this.recordsList[index].reason,
                managerExists: this.recordsList[index].managerExists,
                logs: this.recordsList[index]?.logs,
              });
            }
          }
          this.handleEvents();
        });
        this.hideLoader();
      },
      (err) => {
        this.showErrorToast(`Error in fetching records ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  handleEvents() {
    this.calendarOptions.events = this.eventsArray;
    this.calendarOptions.resources = this.resourcesArray;
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  handleMonthChange(payload) {
    this.recordFilter['startDate'] = new Date(payload.start);
    this.recordFilter['endDate'] = new Date(payload.end);
    if (this.resourcesArray !== undefined && this.resourcesArray !== null && this.resourcesArray.length > 0) {
      this.getRecordsList();
    }
  }

  handleEventClick(selectInfo) {
    const result = this.eventsArray.filter((event) => event.id === selectInfo.event._def.publicId);
    if (result.length > 0) {
      const userDetails = this.resourcesArray.filter((event) => event.id === result[0].userId);
      this.selectedUser = {
        userId: result[0].userId,
        firstName: userDetails[0].firstName,
        lastName: userDetails[0].lastName,
        email: userDetails[0].email,
      };
      this.openEventModal(result[0]);
    } else {
      this.showErrorToast('Event not exist');
    }
  }

  openEventModal(eventInfo) {
    if (!eventInfo.id) {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        width: '60%',
        height: '80%',
        data: {
          selectInfo: eventInfo,
          currentUser: this.currentUser,
          absenceTypes: this.absenceTypes,
          selectedUser: this.selectedUser,
          isManager: true,
        },
        panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
      };
      const dialogRef: MatDialogRef<CreateEventComponent, any> = this.matDialog.open(CreateEventComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((response: any) => {
        if (response.status) {
          this.getRecordsList();
        }
      });
    } else {
      const dialogConfig: MatDialogConfig = {
        disableClose: true,
        width: '60%',
        height: '80%',
        data: {
          selectInfo: eventInfo,
          currentUser: this.currentUser,
          absenceTypes: this.absenceTypes,
          selectedUser: this.selectedUser,
          isManager: true,
        },
        panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
      };
      const dialogRef: MatDialogRef<UpdateEventComponent, any> = this.matDialog.open(UpdateEventComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((response: any) => {
        if (response.status) {
          this.getRecordsList();
        }
      });
    }
  }
}
