import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { SupabaseService } from 'app/core/auth/supabase.service';
import { Subject, takeUntil } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  currentUser: any;

  constructor(private _httpClient: HttpClient, private _supabaseService: SupabaseService) {
    this._supabaseService.getCurrentUser$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: any) => {
      this.currentUser = user;
    });
  }

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get(): Observable<Navigation> {
    return this._httpClient.get<Navigation>('api/common/navigation').pipe(
      tap((navigation) => {
        for (const key in navigation) {
          if (key === 'futuristic') {
            for (let i = 0; i < navigation.futuristic.length; i++) {
              if (navigation.futuristic[i].id === 'dashboards') {
                navigation.futuristic[i].hidden = () => false;
              } else if (navigation.futuristic[i].id === 'superAdmin') {
                if (this.currentUser.isTenant) {
                  navigation.futuristic[i].hidden = () => true;
                } else {
                  // eslint-disable-next-line max-len
                  const res = this._supabaseService.hasMenuPermission(
                    this.currentUser?.permission,
                    navigation.futuristic[i].modules,
                    navigation.futuristic[i].path
                  );
                  navigation.futuristic[i].hidden = () => res;
                }
                if (navigation.futuristic[i]?.children.length > 0) {
                  for (let j = 0; j < navigation.futuristic[i]?.children.length; j++) {
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line max-len
                    const res = this._supabaseService.hasMenuPermission(
                      this.currentUser?.permission,
                      navigation.futuristic[i]?.children[j].modules,
                      navigation.futuristic[i]?.children[j]?.path
                    );
                    navigation.futuristic[i].children[j].hidden = () => res;
                    if (navigation.futuristic[i]?.children[j]?.children.length > 0) {
                      for (let k = 0; k < navigation.futuristic[i]?.children[j]?.children.length; k++) {
                        // eslint-disable-next-line max-len
                        const res = this._supabaseService.hasMenuPermission(
                          this.currentUser?.permission,
                          navigation.futuristic[i]?.children[j]?.children[k].modules,
                          navigation.futuristic[i]?.children[j]?.children[k].path
                        );
                        navigation.futuristic[i].children[j].children[k].hidden = () => res;
                      }
                    }
                  }
                }
              } else {
                // eslint-disable-next-line max-len
                const res = this._supabaseService.hasMenuPermission(
                  this.currentUser?.permission,
                  navigation.futuristic[i].modules,
                  navigation.futuristic[i]?.path
                );
                navigation.futuristic[i].hidden = () => res;
                if (navigation.futuristic[i]?.children.length > 0) {
                  for (let j = 0; j < navigation.futuristic[i]?.children.length; j++) {
                    // eslint-disable-next-line max-len
                    const res = this._supabaseService.hasMenuPermission(
                      this.currentUser?.permission,
                      navigation.futuristic[i]?.children[j].modules,
                      navigation.futuristic[i]?.children[j]?.path
                    );
                    navigation.futuristic[i].children[j].hidden = () => res;
                    if (navigation.futuristic[i]?.children[j]?.children.length > 0) {
                      for (let k = 0; k < navigation.futuristic[i]?.children[j]?.children.length; k++) {
                        const res = this._supabaseService.hasMenuPermission(
                          this.currentUser?.permission,
                          navigation.futuristic[i]?.children[j]?.children[k].modules,
                          navigation.futuristic[i]?.children[j]?.children[k].path
                        );
                        navigation.futuristic[i].children[j].children[k].hidden = () => res;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        this._navigation.next(navigation);
      })
    );
  }
}
