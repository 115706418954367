import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { CoreCommonModule } from 'app/core/common/common.module';
import { CalendarRoutes } from './calendar.routing';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
import { TeamCalendarResourceComponent } from './team-calendar-resource/team-calendar-resource.component';
import { TeamCalendarComponent } from './team-calendar/team-calendar.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { UpdateEventComponent } from './update-event/update-event.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseHighlightModule } from '@fuse/components/highlight';

export const DATETIME_FORMATS = {
  parse: {
    dateInput: 'l, L, LTS',
  },
  display: {
    dateInput: 'DD/MM/YY HH:mm:ss',
    monthYearLabel: 'yyyy MMMM',
    dateA11yLabel: 'MMMM d, y', //'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [MyCalendarComponent, TeamCalendarComponent, TeamCalendarResourceComponent, CreateEventComponent, UpdateEventComponent],
  imports: [
    RouterModule.forChild(CalendarRoutes),
    SharedModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatStepperModule,
    MatSelectModule,
    MatSortModule,
    CoreCommonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatPaginatorModule,
    TranslocoModule,
    MatTooltipModule,
    FullCalendarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    FormsModule,
    MatButtonModule,
    NgxMatMomentModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    FuseHighlightModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: NGX_MAT_DATE_FORMATS, useValue: DATETIME_FORMATS },
  ],
})
export class CalendarModule {}
