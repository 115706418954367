<div class="flex flex-auto items-center min-w-0">
  <div class="flex flex-col min-w-0 ml-4">
    <div class="flex items-center for-div">
      <div class="ml-1.5 leading-6 truncate text-secondary titles">
        <div class="master">
          {{ componentName | transloco }}
        </div>
        <div>
          <svg width="1" height="27" viewBox="0 0 1 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="-2.18557e-08" x2="0.500001" y2="27" stroke="black" stroke-opacity="0.5" />
          </svg>
        </div>
        <div class="selectedmodule">
          <a href="#" >{{ rootModuleName | transloco }}</a>
        </div>
        <div class="icons">
          <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.85353 5.61415L0.853523 0.159759C0.658289 -0.053259 0.341688 -0.053259 0.146421 0.159795C-0.0488126 0.372813 -0.0488126 0.718158 0.146454 0.931176L4.7929 5.9999L0.146421 11.0689C-0.0488126 11.2819 -0.0488126 11.6272 0.146454 11.8403C0.244055 11.9468 0.372022 12 0.499989 12C0.627955 12 0.755923 11.9468 0.853556 11.8402L5.85353 6.38553C5.94733 6.28324 6 6.14452 6 5.99986C6 5.85521 5.94733 5.71644 5.85353 5.61415Z"
              fill="#1E293B" />
          </svg>
        </div>
      </div>
      <div class="currentModule">
        {{ moduleName | transloco }}
      </div>
    </div>
  </div>
</div>