import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

//Base service
import { BaseApiService } from '../../../../shared/services/API-service/base-service';

@Injectable({ providedIn: 'root' })
export class DepartmentService extends BaseApiService {
  private _departments: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _departmentsCount: number;

  constructor(private _http: HttpClient) {
    super('tenant/departments', _http);
  }

  get departments$(): Observable<any> {
    return this._departments.asObservable();
  }
  get departmentsCount$() {
    return this._departmentsCount;
  }

  getAll(filter: any): Observable<any[]> {
    return this.post(`listing`, filter).pipe(
      tap((response) => {
        this._departmentsCount = response.count;
        this._departments.next(response.result);
      })
    );
  }

  create(department: any) {
    return this.post(`create`, department).pipe(
      map((newDepartment) => {
        return newDepartment;
      })
    );
  }

  update(id: string, department: any) {
    return this.post(`update`, department).pipe(
      map((updatedDepartment) => {
        return updatedDepartment;
      })
    );
  }

  userFilterHierarchy() {
    return this.post(`user-filter-hierarchy`).pipe(
      map((userList) => {
        return userList;
      })
    );
  }

  departmentFilterHierarchy(inputs: any) {
    return this.post(`hierarchical-filter-list`, inputs).pipe(
      map((departmentList) => {
        return departmentList;
      })
    );
  }
}
