import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ControlMessagesComponent } from './components/control-messages';

import { SortIconComponent } from './components/table';
import { SearchComponent } from './components/search';
import { NumericInputDirective } from './directives/numeric-input.directive';
import { NumericFormatDirective } from './directives/numeric-format.directive';
import { TrimTextDirective } from './directives/trim-text.directive';

import { FILTERS } from '../lib/filters';

@NgModule({
  imports: [MatFormFieldModule, MatInputModule, MatSelectModule, MatIconModule, FormsModule],
  declarations: [ControlMessagesComponent, SortIconComponent, SearchComponent, NumericInputDirective, NumericFormatDirective, TrimTextDirective, FILTERS],
  exports: [ControlMessagesComponent, SortIconComponent, SearchComponent, NumericInputDirective, NumericFormatDirective, TrimTextDirective, FILTERS],
})
export class CoreCommonModule {}
