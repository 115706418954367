import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap, map } from 'rxjs';
import { BaseApiService } from '../../../shared/services/API-service/base-service';
@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseApiService {
  private _notifications: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _notificationCount: number;
  constructor(private _http: HttpClient) {
    super('notifications', _http);
  }

  get notifications$(): Observable<any[]> {
    return this._notifications.asObservable();
  }

  get notificationCount$() {
    return this._notificationCount;
  }

  getAll(filter: any): Observable<any[]> {
    return this.post(`getNotification`, filter).pipe(
      tap((response) => {
        this._notificationCount = response.count;
        this._notifications.next(response.result);
      })
    );
  }

  markRead(id: any) {
    return this.post(`markAsRead`, { _id: id }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
