import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limitTo' })
export class TruncatePipe implements PipeTransform {
  public transform(value: string, limit = 25, completeWords = false, ellipsis = '..'): string {
    if (completeWords) {
      if (value !== undefined && value !== null && value.length > limit) {
        limit = value.substr(0, limit).lastIndexOf(' ');
      }
    }
    if (value !== undefined && value !== null && value.length > limit) {
      return `${value.substr(0, limit)}${ellipsis}`;
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'splitByValue' })
export class SplitByValue implements PipeTransform {
  public transform(value: string, args: string[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 4) : 4;
    const trail = args.length > 1 ? args[1] : '';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

@Pipe({ name: 'newline' })
export class NewlinePipe implements PipeTransform {
  public transform(value: string, args: string[]): any {
    if (value !== null && value !== '' && value !== null) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
  }
}

@Pipe({ name: 'capitalizeFirst' })
export class CapitalizeFirstPipe implements PipeTransform {
  public transform(value: string): string {
    if (value === undefined || value === null) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}

@Pipe({ name: 'underscoreToSpace' })
export class UnderscoreToSpace implements PipeTransform {
  public transform(value: any): string {
    if (value === null || value === '') {
      return '';
    }
    return value.replaceAll('_', ' ');
  }
}

@Pipe({ name: 'splitCSV' })
export class SplitCSV implements PipeTransform {
  public transform(value: string, args: string[]): any {
    const splitted = value.split(',');
    let returnValue = '';
    let i;
    for (i = 0; i < splitted.length; +i++) {
      returnValue = returnValue + (i + 1) + '. ' + splitted[i].trim() + '<br>';
    }
    return returnValue;
  }
}

@Pipe({ name: 'round' })
export class Round implements PipeTransform {
  public transform(value: number, args: string): any {
    const mult = 1;
    value = !value ? 0 : Number(value);
    if (value !== undefined && value !== null) {
      if (args === 'up') {
        return Math.ceil(value / mult) * mult;
      } else if (args === 'down') {
        return Math.floor(value / mult) * mult;
      } else {
        return Math.round(value / mult) * mult;
      }
    }
  }
}

@Pipe({ name: 'ucwordswithsingle' })
export class UcWordsWithSingle implements PipeTransform {
  public transform(value: string, args: string[]): any {
    const splitted = value.split(' ');
    let returnValue = '';
    let i;
    for (i = 0; i < splitted.length; +i++) {
      if (i < 2) {
        if (returnValue === '') {
          returnValue = splitted[i].charAt(0);
        } else {
          returnValue = returnValue + ' ' + splitted[i].charAt(0);
        }
      }
    }
    return returnValue;
  }
}

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
  public transform(value: any, args: any[] = null): any {
    return Object.keys(value); // .map(key => value[key]);
  }
}

@Pipe({ name: 'timeAgo' })
export class TimeAgo implements PipeTransform {
  public transform(inputDate: Date): string {
    const current = new Date().valueOf();
    const input = new Date(inputDate).valueOf();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;
    const elapsed = current - input;
    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours';
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + ' days';
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + ' months';
    } else {
      return Math.round(elapsed / msPerYear) + ' years';
    }
  }
}

@Pipe({ name: 'mapToIterable' })
export class MapToIterable implements PipeTransform {
  public transform(dict: any): any {
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ key, val: dict[key] });
      }
    }
    return a;
  }
}

@Pipe({ name: 'htmlToPlaintext' })
export class HtmlToPlaintext implements PipeTransform {
  public transform(value: string, args: string[]): any {
    return value ? String(value).replace(/<[^>]+>/gm, '') : '';
  }
}

export const FILTERS = [
  TruncatePipe,
  SplitByValue,
  NewlinePipe,
  CapitalizeFirstPipe,
  UnderscoreToSpace,
  SplitCSV,
  Round,
  UcWordsWithSingle,
  KeysPipe,
  TimeAgo,
  MapToIterable,
  HtmlToPlaintext,
];
