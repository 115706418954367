import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SupabaseService } from 'app/core/auth/supabase.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanMatch {
  /**
   * Constructor
   */
  constructor(private _supabaseService: SupabaseService, private _router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can match
   *
   * @param route
   * @param segments
   */
  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check(segments);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param segments
   * @private
   */
  private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
    // Check the authentication status
    if (!this._supabaseService.session) {
      // Redirect to the sign-in page with a redirectUrl param
      const redirectURL = `/${segments.join('/')}`;
      const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
      return of(urlTree);
    } else {
      return of(true);
    }
  }
}
