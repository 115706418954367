import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  public previousValue = '';
  private dotAlreadyPresent = false;
  private minusAlreadyPresent = false;

  constructor(@Inject(ElementRef) private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  public onKeyDown(event): any {
    const e = event as KeyboardEvent;

    // Save the previous value of field in variable. Used to reset value is final output after key up is not a number.
    this.previousValue = this.el.nativeElement.value;
    const keyCode = e.keyCode;
    // 8 -> Back space
    // 9 -> Tab
    // 13 -> Carriage return
    // 27 -> Escape
    // 35 - 39 -> cursor control characters
    // 39 -> Left arrow
    // 46 -> '.'
    // 190 -> '.'
    if (
      [8, 9, 13, 27].indexOf(keyCode) !== -1 ||
      (!this.dotAlreadyPresent && (keyCode === 46 || keyCode === 190)) ||
      (keyCode >= 35 && keyCode <= 39) || // Cursor control characters
      (keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) || // For select all content of text box ('Ctrl (or Cmd) + A')
      (keyCode === 97 && (e.ctrlKey === true || e.metaKey === true)) || // For select all content of text box ('Ctrl (or Cmd) + a')
      (keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) || // For copy content of text box ('Ctrl (or Cmd) + C')
      (keyCode === 99 && (e.ctrlKey === true || e.metaKey === true)) || // For copy content of text box ('Ctrl (or Cmd) + c')
      (keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) || // For cut content of text box ('Ctrl (or Cmd) + X')
      (keyCode === 120 && (e.ctrlKey === true || e.metaKey === true)) || // For cut content of text box ('Ctrl (or Cmd) + x')
      (keyCode === 86 && (e.ctrlKey === true || e.metaKey === true)) || // For paste content of text box ('Ctrl (or Cmd) + V')
      (keyCode === 118 && (e.ctrlKey === true || e.metaKey === true)) || // For paste content of text box ('Ctrl (or Cmd) + v'))
      (keyCode === 90 && (e.ctrlKey === true || e.metaKey === true)) || // For cut content of text box ('Ctrl (or Cmd) + Z')
      (keyCode === 122 && (e.ctrlKey === true || e.metaKey === true)) // For cut content of text box ('Ctrl (or Cmd) + z')
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event): any {
    const e = event as KeyboardEvent;
    const isNumeric = (val: string): boolean => !isNaN(Number(val));
    if (isNumeric(this.el.nativeElement.value)) {
      // The value is a number
    } else {
      // The value is not a number, replace the value with old value that was before editing.
      this.el.nativeElement.value = this.previousValue;
      e.preventDefault();
    }
  }
}
