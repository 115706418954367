<button
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="notification.length > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{count}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>
<ng-template #notificationsPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-110 sm:w-110 sm:rounded-2xl overflow-hidden shadow-lg">
        <div class="flex shrink-0 items-center py-2 pr-4 pl-6 " style="background-color: #EBF5DD;" >
            <div class="text-lg font-medium leading-10" style="color:black;">Notification</div>
            <div class="ml-auto">
                <button class="bg-primary py-1 px-4 mr-2" style="border-radius: 15px; color:white;" (click)="callBothFunctions()">View All</button>
                <button class="mr-4" (click)="closePanel()">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.78571 8.78572C10.0714 9.07143 10.0714 9.5 9.78571 9.78571C9.64286 9.92857 9.5 10 9.28571 10C9.07143 10 8.92857 9.92857 8.78572 9.78571L5 6L1.21429 9.78571C1.07143 9.92857 0.928571 10 0.714286 10C0.5 10 0.357143 9.92857 0.214286 9.78571C-0.0714286 9.5 -0.0714286 9.07143 0.214286 8.78572L4 5L0.214286 1.21429C-0.0714286 0.928571 -0.0714286 0.5 0.214286 0.214286C0.5 -0.0714286 0.928571 -0.0714286 1.21429 0.214286L5 4L8.78572 0.214286C9.07143 -0.0714286 9.5 -0.0714286 9.78571 0.214286C10.0714 0.5 10.0714 0.928571 9.78571 1.21429L6 5L9.78571 8.78572Z" fill="black"/>
                        </svg>                        
                </button>
            </div>
        </div>
        <div class="relative flex flex-col  sm:max-h-120  overflow-y-auto bg-card">
            <ng-container *ngFor="let notification of notification; let i = index;">
                <div class="flex pt-2 pb-2 pointer" (click)="navigate(notification)">
                    <ng-container>
                        <div class="flex pl-2 pr-2">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>
                </div>
                <ng-template #notificationContent>
                    <div [ngStyle]="getBackgroundStyle(i)"  class="flex flex-col p-2 py-4 ml-1 pointer" style="border-radius: 10px; width: 410px;" >
                        <ng-container>
                            <div class="line-clamp-1">
                                <span span class="font-semibold">{{ notification.message}}</span>
                            </div>
                        </ng-container>
                        <ng-container>
                            <div class="line-clamp-1">
                                Leave Type: - {{ notification.absenceType }}
                            </div>
                        </ng-container>
                        <ng-container>
                            <div class="line-clamp-1">
                                Date Requested:
                                 {{ notification.startDate | date:'medium' }}
                                |
                                {{ notification.endDate | date:'medium' }}
                            </div>
                        </ng-container>
                        <div class="mt-2 ml-auto mr-2 text-sm leading-none text-secondary">
                            {{notification.createdAt | date:'medium'}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!notification || !notification.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-8 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon
                            class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No unread notification found</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have notification, they will appear here.</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
