import { Component, Input, SimpleChange, OnChanges } from '@angular/core';
@Component({
  selector: 'app-sort-icon',
  template: `
    <mat-icon class="cursor-pointer" style="font-size:12pt;padding-left:5px;padding-top:0px">{{
      sortState == 'asc' ? 'arrow_upward' : 'arrow_downward'
    }}</mat-icon>
  `,
})
export class SortIconComponent implements OnChanges {
  @Input() public sortState: any;

  public ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if ('sortState' in changes) {
      this.sortState = changes.sortState.currentValue;
    }
  }
}
