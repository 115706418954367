import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CustomValidators } from '../../../lib';

@Component({
  selector: 'mat-error',
  template: `
    {{ errorMessage }}
  `,
})
export class ControlMessagesComponent {
  @Input() public control: UntypedFormControl = new UntypedFormControl();
  @Input() public fieldName;
  get errorMessage(): string {
    if (this.control && this.control.errors) {
      for (const propertyName in this.control.errors) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.control.errors.hasOwnProperty(propertyName)) {
          // return this.fieldName + ' ' + CustomValidators.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
          return ' ' + CustomValidators.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        }
      }
    }

    return null;
  }
}
