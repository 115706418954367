import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';

@Component({
  selector: 'languages',
  templateUrl: './languages.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
  availableLangs: AvailableLangs;
  activeLang: string;
  flagCodes: any;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the available languages from transloco
    this.availableLangs = this._translocoService.getAvailableLangs();

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe((activeLang) => {
      // Get the active lang
      this.activeLang = activeLang;

      // Update the navigation
      this._updateNavigation(activeLang);
    });

    // Set the country iso codes for languages for flags
    this.flagCodes = {
      en: 'us',
      tr: 'tr',
      de: 'de',
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  setActiveLang(lang: string): void {
    // Set the active lang
    this._translocoService.setActiveLang(lang);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the navigation
   *
   * @param lang
   * @private
   */
  private _updateNavigation(lang: string): void {
    // For the demonstration purposes, we will only update the Dashboard names
    // from the navigation but you can do a full swap and change the entire
    // navigation data.
    //
    // You can import the data from a file or request it from your backend,
    // it's up to you.

    // Get the component -> navigation data -> item
    const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

    // Return if the navigation component does not exist
    if (!navComponent) {
      return null;
    }

    // Get the flat navigation data
    const navigation = navComponent.navigation;

    // Get the Project dashboard item and update its title
    const projectItem = this._fuseNavigationService.getItem('dashboards', navigation);
    if (projectItem) {
      this._translocoService
        .selectTranslate('MENU.dashboard')
        .pipe(take(1))
        .subscribe((translation) => {
          // Set the title
          projectItem.title = translation;

          // Refresh the navigation component
          navComponent.refresh();
        });
    }

    // Get the Project Tenants Item and it's childrens and update title
    const tenantsItem = this._fuseNavigationService.getItem('tenants', navigation);
    if (tenantsItem) {
      this._translocoService
        .selectTranslate('MENU.tenants')
        .pipe(take(1))
        .subscribe((menu) => {
          // Set the title
          tenantsItem.title = menu;
          if (tenantsItem.children && tenantsItem.children.length) {
            for (let i = 0; i < tenantsItem.children.length; i++) {
              this._translocoService
                .selectTranslate(`MENU.SUBMENU.${tenantsItem.children[i].id}`)
                .pipe(take(1))
                .subscribe((subMenu) => {
                  tenantsItem.children[i].title = subMenu;
                  if (tenantsItem.children[i].children && tenantsItem.children[i].children.length) {
                    for (let j = 0; j < tenantsItem.children[i].children.length; j++) {
                      this._translocoService
                        .selectTranslate(`MENU.SUBMENU.CHILDREN.${tenantsItem.children[i].children[j].id}`)
                        .pipe(take(1))
                        .subscribe((child) => {
                          tenantsItem.children[i].children[j].title = child;
                        });
                    }
                  }
                });
            }
          }
          // Refresh the navigation component
          navComponent.refresh();
        });
    }

    // Get the Project Tenants Item and it's childrens and update title
    const crmItem = this._fuseNavigationService.getItem('crm', navigation);
    if (crmItem) {
      this._translocoService
        .selectTranslate('MENU.crm')
        .pipe(take(1))
        .subscribe((menu) => {
          // Set the title
          crmItem.title = menu;
          if (crmItem.children && crmItem.children.length) {
            for (let i = 0; i < crmItem.children.length; i++) {
              this._translocoService
                .selectTranslate(`MENU.SUBMENU.${crmItem.children[i].id}`)
                .pipe(take(1))
                .subscribe((subMenu) => {
                  crmItem.children[i].title = subMenu;
                  if (crmItem.children[i].children && crmItem.children[i].children.length) {
                    for (let j = 0; j < crmItem.children[i].children.length; j++) {
                      this._translocoService
                        .selectTranslate(`MENU.SUBMENU.CHILDREN.${crmItem.children[i].children[j].id}`)
                        .pipe(take(1))
                        .subscribe((child) => {
                          crmItem.children[i].children[j].title = child;
                        });
                    }
                  }
                });
            }
          }
          // Refresh the navigation component
          navComponent.refresh();
        });
    }

    // Get the Project Tenants Item and it's childrens and update title
    const superAdminItem = this._fuseNavigationService.getItem('superAdmin', navigation);
    if (superAdminItem) {
      this._translocoService
        .selectTranslate('MENU.superAdmin')
        .pipe(take(1))
        .subscribe((menu) => {
          // Set the title
          superAdminItem.title = menu;
          if (superAdminItem.children && superAdminItem.children.length) {
            for (let i = 0; i < superAdminItem.children.length; i++) {
              this._translocoService
                .selectTranslate(`MENU.SUBMENU.${superAdminItem.children[i].id}`)
                .pipe(take(1))
                .subscribe((subMenu) => {
                  superAdminItem.children[i].title = subMenu;
                  if (superAdminItem.children[i].children && superAdminItem.children[i].children.length) {
                    for (let j = 0; j < superAdminItem.children[i].children.length; j++) {
                      this._translocoService
                        .selectTranslate(`MENU.SUBMENU.CHILDREN.${superAdminItem.children[i].children[j].id}`)
                        .pipe(take(1))
                        .subscribe((child) => {
                          superAdminItem.children[i].children[j].title = child;
                        });
                    }
                  }
                });
            }
          }
          // Refresh the navigation component
          navComponent.refresh();
        });
    }

    // Get the Project dashboard item and update its title
    const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
    if (projectDashboardItem) {
      this._translocoService
        .selectTranslate('Project')
        .pipe(take(1))
        .subscribe((translation) => {
          // Set the title
          projectDashboardItem.title = translation;

          // Refresh the navigation component
          navComponent.refresh();
        });
    }

    // Get the Analytics dashboard item and update its title
    const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
    if (analyticsDashboardItem) {
      this._translocoService
        .selectTranslate('Analytics')
        .pipe(take(1))
        .subscribe((translation) => {
          // Set the title
          analyticsDashboardItem.title = translation;

          // Refresh the navigation component
          navComponent.refresh();
        });
    }
  }
}
