import { Inject } from '@angular/core';
import { AppComponent } from '../../app.component';
/**
 * This is a base class for all components that are defined in this application
 * The class provide the base functionality for performing common operation on
 * UI
 */
export abstract class VisionAppComponent {
  /**
   *
   * @param mainAppComponent      An Instance of AppComponent class is required
   *                              for provisioning and performin the operations
   *                              in base UI.
   */
  constructor(@Inject(AppComponent) private mainAppComponent: AppComponent) {}

  /**
   * Shows loading bar
   */
  protected showLoader() {
    this.mainAppComponent._ngxSpinnerService.show();
  }

  /**
   * Hides loading bar.
   */
  protected hideLoader() {
    this.mainAppComponent._ngxSpinnerService.hide();
  }

  /**
   * ---------------------------------------------------------------------------
   * Shows a success toast to user
   * ---------------------------------------------------------------------------
   *
   * @param message       Message for toast
   * @param title         Title for toast windows. Default: 'Success'
   *
   */
  protected showSuccessToast(message, title = 'Success'): void {
    if (Array.isArray(message)) {
      let messageString = '';
      for (const item of message) {
        messageString = messageString + item + '<br>';
      }
      this.mainAppComponent._matSnackBar.open(messageString, title, {
        duration: 10000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['green-snackbar'],
      });
    } else {
      this.mainAppComponent._matSnackBar.open(message, title, {
        duration: 10000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['green-snackbar'],
      });
    }
  }

  /**
   * ---------------------------------------------------------------------------
   * Shows a error toast to user
   * ---------------------------------------------------------------------------
   *
   * @param message       Message for toast
   * @param title         Title for toast windows. Default: 'Error'
   *
   */
  protected showErrorToast(message, title = 'Error'): void {
    if (Array.isArray(message)) {
      let messageString = '';
      for (const item of message) {
        messageString = messageString + item + '<br>';
      }
      this.mainAppComponent._matSnackBar.open(messageString, title, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['error-snackbar'],
      });
    } else {
      if (message && message?.code && message?.code === 'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED') {
        this.mainAppComponent.goToLogin();
      }
      this.mainAppComponent._matSnackBar.open(message, title, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['error-snackbar'],
      });
    }
  }

  /**
   * ---------------------------------------------------------------------------
   * Shows a warning toast to user
   * ---------------------------------------------------------------------------
   *
   * @param message       Message for toast
   * @param title         Title for toast windows. Default: 'Warning'
   *
   */
  protected showWarningToast(message, title = 'Warning'): void {
    if (Array.isArray(message)) {
      let messageString = '';
      for (const item of message) {
        messageString = messageString + item + '<br>';
      }
      this.mainAppComponent._matSnackBar.open(messageString, title, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['warning-snackbar'],
      });
    } else {
      if (message && message?.code && message?.code === 'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED') {
        this.mainAppComponent.goToLogin();
      }
      this.mainAppComponent._matSnackBar.open(message, title, {
        duration: 5000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['warning-snackbar'],
      });
    }
  }
}
