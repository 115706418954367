import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../../modules/admin/hr-masters/users/users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { VisionAppComponent } from '../../../core/base-classes/vision-app.component';
import { AppComponent } from '../../../app.component';
import { Constants } from '../../../modules/admin/hr-masters/roles/permissions/constants';
import { SupabaseService } from '../../../core/auth/supabase.service';
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends VisionAppComponent implements OnInit {
  record: any;
  detailsFields: any;

  constructor(
    @Inject(AppComponent) private appComponent: AppComponent,
    private _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserProfileComponent>,
    private _spinner: NgxSpinnerService,
    private _supabaseService: SupabaseService
  ) {
    super(appComponent);
    this.detailsFields = {};
    for (let index = 0; index < Constants.userMaster.details.length; index++) {
      const res = this._supabaseService.hasFieldPermissions(
        this.data?.currentUser?.permission,
        'User',
        '/users/profileDetail',
        Constants.userMaster.details[index].field
      );
      if (res) {
        this.detailsFields[Constants.userMaster.details[index].field] = res;
      } else {
        this.detailsFields[Constants.userMaster.details[index].field] = Constants.userMaster.details[index];
      }
    }
  }

  userId = this.data?.record?._id || this.data?.currentUser?.userId;

  // Create an object with the userId property
  requestObject = {
    userId: this.userId,
  };

  ngOnInit(): void {
    this.showLoader();
    this._userService.details(this.requestObject).subscribe(
      (data) => {
        this.record = data;
        this.hideLoader();
      },
      (err) => {
        this.showErrorToast(`Error in fetching user details ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  viewAccess(fields) {
    const fieldsArray = fields.split(',');
    let returnValue = false;
    for (let index = 0; index < fieldsArray.length; index++) {
      if (this.detailsFields[fieldsArray[index]].read) {
        returnValue = true;
        break;
      }
    }
    return returnValue;
  }
}
