import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { SharedModule } from 'app/shared/shared.module';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CalendarModule } from '../../../modules/admin/hr-masters/calendar/calendar.module';

@NgModule({
  declarations: [NotificationsComponent, ViewDetailsComponent],
  imports: [
    RouterModule,
    OverlayModule,
    PortalModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    MatTabsModule,
    MatPaginatorModule,
    CalendarModule,
  ],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
