import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
// import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboards' },
  { path: 'access_token', pathMatch: 'full', redirectTo: 'dashboards' },
  // Auth routes for guests
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule) },
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule) },
    ],
  },
  // Admin routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'futuristic',
    },
    children: [
      // Dashboards
      { path: 'dashboards', loadChildren: () => import('app/modules/admin/dashboards/dashboard.module').then((m) => m.DashboardModule) },
      { path: 'companies', loadChildren: () => import('app/modules/admin/super-admin/tenants/tenants.module').then((m) => m.CompanyModule) },
      { path: 'countries', loadChildren: () => import('app/modules/admin/super-admin/countries/countries.module').then((m) => m.CountriesModule) },
      { path: 'languages', loadChildren: () => import('app/modules/admin/super-admin/languages/languages.module').then((m) => m.LanguagesModule) },
      {
        path: 'company-types',
        loadChildren: () => import('app/modules/admin/super-admin/company-types/company-types.module').then((m) => m.CompanyTypesModule),
      },
      {
        path: 'officeLocation',
        loadChildren: () => import('app/modules/admin/super-admin/officeLocation/officeLocation.module').then((m) => m.OfficeLocationModule),
      },
      {
        path: 'state',
        loadChildren: () => import('app/modules/admin/super-admin/state/state.module').then((m) => m.StateModule),
      },
      {
        path: 'city',
        loadChildren: () => import('app/modules/admin/super-admin/city/city.module').then((m) => m.CityModule),
      },

      // CRM

      {
        path: 'leads',
        loadChildren: () => import('app/modules/admin/crm-masters/leads/leads.module').then((m) => m.LeadsModule),
      },
      {
        path: 'contact-role',
        loadChildren: () => import('app/modules/admin/crm-masters/contact-role/contact-role.module').then((m) => m.ContactRoleModule),
      },
      {
        path: 'business-territory',
        loadChildren: () => import('app/modules/admin/crm-masters/business-territory/business-territory.module').then((m) => m.BusinessTerritoryModule),
      },
      {
        path: 'job-title',
        loadChildren: () => import('app/modules/admin/crm-masters/job-title/job-title.module').then((m) => m.JobTitleModule),
      },
      {
        path: 'product-interest',
        loadChildren: () => import('app/modules/admin/crm-masters/product-interest/product-interest.module').then((m) => m.ProductInterestModule),
      },
      {
        path: 'lead-source',
        loadChildren: () => import('app/modules/admin/crm-masters/account-source/account-source.module').then((m) => m.AccountSourceModule),
      },
      {
        path: 'subBusiness-territory',
        loadChildren: () =>
          import('app/modules/admin/crm-masters/subBusiness-territory/subBusiness-territory.module').then((m) => m.SubBusinessTerritoryModule),
      },
      {
        path: 'lead-department',
        loadChildren: () => import('app/modules/admin/crm-masters/lead-department/lead-department.module').then((m) => m.LeadDepartmentModule),
      },
      {
        path: 'lead-companyType',
        loadChildren: () => import('app/modules/admin/crm-masters/lead-companyType/lead-companyType.module').then((m) => m.LeadCompanyTypeModule),
      },

      {
        path: 'lead-status',
        loadChildren: () => import('app/modules/admin/crm-masters/lead-status/lead-status.module').then((m) => m.LeadStatusModule),
      },

      // HR Masters
      {
        path: 'tenants',
        children: [
          { path: 'departments', loadChildren: () => import('app/modules/admin/hr-masters/departments/departments.module').then((m) => m.DepartmentsModule) },
          { path: 'users', loadChildren: () => import('app/modules/admin/hr-masters/users/users.module').then((m) => m.UsersModule) },
          {
            path: 'userProfile',
            loadChildren: () => import('app/modules/admin/hr-masters/user-profile/user-profile.module').then((m) => m.UserProfilesModule),
          },
          { path: 'roles', loadChildren: () => import('app/modules/admin/hr-masters/roles/roles.module').then((m) => m.RolesModule) },
          {
            path: 'payment-type',
            loadChildren: () => import('app/modules/admin/hr-masters/payment-type/payment-type.module').then((m) => m.PaymentTypeModule),
          },
          {
            path: 'currency-type',
            loadChildren: () => import('app/modules/admin/hr-masters/currency-master/currency-master.module').then((m) => m.CurrencyTypeModule),
          },
          { path: 'contracts', loadChildren: () => import('app/modules/admin/hr-masters/contracts/contracts.module').then((m) => m.ContractsModule) },
          { path: 'tax-class', loadChildren: () => import('app/modules/admin/hr-masters/tax-class/tax-class.module').then((m) => m.TaxClassModule) },
          {
            path: 'health-Insurance',
            loadChildren: () => import('app/modules/admin/hr-masters/health-insurance/health-insurance.module').then((m) => m.HealthInsuranceModule),
          },
          {
            path: 'financial-years',
            loadChildren: () => import('app/modules/admin/hr-masters/financial-years/financial-years.module').then((m) => m.FinancialYearsModule),
          },
          { path: 'position', loadChildren: () => import('app/modules/admin/hr-masters/position/position.module').then((m) => m.PositionModule) },
          { path: 'cost-centers', loadChildren: () => import('app/modules/admin/hr-masters/cost-center/cost-center.module').then((m) => m.CostCenterModule) },
          { path: 'calendar', loadChildren: () => import('app/modules/admin/hr-masters/calendar/calendar.module').then((m) => m.CalendarModule) },
          {
            path: 'userProfile',
            loadChildren: () => import('app/modules/admin/hr-masters/user-profile/user-profile.module').then((m) => m.UserProfilesModule),
          },
          { path: 'branch', loadChildren: () => import('app/modules/admin/hr-masters/branch/branch.module').then((m) => m.BranchModule) },
        ],
      },
      // Pages
      {
        path: 'pages',
        children: [
          {
            path: 'error',
            children: [
              { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then((m) => m.Error404Module) },
              { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then((m) => m.Error500Module) },
            ],
          },
          // Profile
          { path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then((m) => m.ProfileModule) },
        ],
      },
      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then((m) => m.Error404Module),
      },
    ],
  },
];
