import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent {
  @Output() public search = new EventEmitter<any>();
  public searchString = '';
  searchActive = true;

  public searchItem(searchString) {
    if (searchString !== '') {
      this.searchActive = false;
      this.search.emit(searchString);
    }
  }
  public onSearch(searchString, searchActiveFlag) {
    if (searchString !== '') {
      if (searchActiveFlag) {
        this.searchActive = false;
        this.search.emit(this.searchString);
      } else {
        this.searchString = '';
        this.searchActive = true;
        this.search.emit('');
      }
    } else {
      if (!searchActiveFlag) {
        this.searchString = '';
        this.searchActive = true;
        this.search.emit('');
      }
    }
  }

  onChange(value) {
    if (value === '') {
      this.searchActive = true;
      this.search.emit('');
    }
  }
}
