import { Injectable } from '@angular/core';
import { AuthChangeEvent, AuthSession, createClient, Session, SupabaseClient, User } from '@supabase/supabase-js';
import { environment } from 'environments/environment';
import { UserService } from 'app/modules/admin/hr-masters/users/users.service';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';

export interface Profile {
  id?: string;
  username: string;
  website: string;
  avatar_url: string;
}

export interface CurrentUser {
  isTenant?: boolean;
  email?: string;
  companyId?: string;
  firstName?: string;
  lastName?: string;
  domainName?: string;
  employeeCodeAutogenerated?: boolean;
  name?: string;
  code?: string;
  roleId?: string;
  userId?: string;
  permission?: [];
  departmentId?: string;
  positionId?: string;
  companyGroupName?: string;
  vacationDetails?: object;
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private _currentUser: ReplaySubject<CurrentUser> = new ReplaySubject<CurrentUser>(1);

  private supabase: SupabaseClient;
  _session: AuthSession | null = null;
  constructor(private _spinnerService: NgxSpinnerService, private _userService: UserService, private _cookieService: CookieService) {
    this.supabase = createClient(environment.SUPABASE.SUPABASE_URL, environment.SUPABASE.SUPABASE_KEY);
  }

  get session() {
    this.supabase.auth.getSession().then(({ data }) => {
      this._session = data.session;
    });
    return this._session;
  }

  profile(user: User) {
    return this.supabase
      .from('profiles')
      .select(`username, website, avatar_url`)
      .eq('id', user.id)
      .single();
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  signIn(email: string) {
    return this.supabase.auth.signInWithOtp({ email });
  }

  signOut() {
    localStorage.clear();
    this._cookieService.deleteAll();
    return this.supabase.auth.signOut();
  }

  updateProfile(profile: Profile) {
    const update = {
      ...profile,
      updated_at: new Date(),
    };

    return this.supabase.from('profiles').upsert(update);
  }

  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file);
  }

  setCurrentUser(): Observable<any> {
    if (this.session) {
      return this._userService.getCurrentUser().pipe(
        tap((response) => {
          this._currentUser.next(response);
        })
      );
    } else {
      this._spinnerService.hide();
    }
  }

  get getCurrentUser$(): Observable<any> {
    return this._currentUser.asObservable();
  }

  public hasMenuPermission(permissions, modules, path) {
    if (modules !== undefined && modules !== null && modules.length > 0) {
      // eslint-disable-next-line max-len
      const result = permissions.filter(
        (permission) => modules.includes(permission.modelName) === true && (permission.url === path || path === undefined || path === null || path === '')
      );
      if (result.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  public hasModulePermission(permissions, modules) {
    if (modules !== undefined && modules !== null && modules.length > 0) {
      const result = permissions.filter((permission) => modules.includes(permission.modelName) === true);
      if (result.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public hasPermissions(permissions, module, path) {
    const result = permissions.filter((permission) => permission.modelName === module && permission.url === path);

    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public hasFieldPermissions(permissions, module, path, field) {
    const result = permissions.filter((permission) => permission.modelName === module && permission.url === path);
    if (result !== undefined && result.length > 0) {
      if (result[0].fields !== undefined && result[0].fields.length > 0) {
        const res = result[0].fields.filter((fields) => fields.field === field);
        if (res !== undefined && res.length > 0) {
          return res[0];
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
