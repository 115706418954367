import { Component, OnInit, Inject } from '@angular/core';
import { CompanyService } from '../../../modules/admin/super-admin/tenants/tenants.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { VisionAppComponent } from '../../../core/base-classes/vision-app.component';
import { AppComponent } from '../../../app.component';
import { Constants } from '../../../modules/admin/hr-masters/roles/permissions/constants';
import { SupabaseService } from '../../../core/auth/supabase.service';
@Component({
  selector: 'company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent extends VisionAppComponent implements OnInit {
  record: any;
  detailsFields: any;

  constructor(
    @Inject(AppComponent) private appComponent: AppComponent,
    private _companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CompanyDetailsComponent>,
    private _spinner: NgxSpinnerService,
    private _supabaseService: SupabaseService
  ) {
    super(appComponent);
    this.detailsFields = {};
    for (let index = 0; index < Constants.companyMaster.details.length; index++) {
      const res = this._supabaseService.hasFieldPermissions(
        this.data?.currentUser?.permission,
        'Company',
        '/companies/detail',
        Constants.companyMaster.details[index].field
      );
      if (res) {
        this.detailsFields[Constants.companyMaster.details[index].field] = res;
      } else {
        this.detailsFields[Constants.companyMaster.details[index].field] = Constants.companyMaster.details[index];
      }
    }
  }
  ngOnInit(): void {
    this.showLoader();
    this._companyService.getRecord(this.data.record._id).subscribe(
      (data) => {
        this.record = data.data;
        this.hideLoader();
      },
      (err) => {
        this.showErrorToast(`Error in fetching user details ${err.error.message}`);
        this.hideLoader();
      }
    );
  }
}
