import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
//Base service
import { BaseApiService } from '../../../../shared/services/API-service/base-service';
@Injectable({ providedIn: 'root' })
export class CalendarService extends BaseApiService {
  private _absence: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _absenceCount: number;
  constructor(private _http: HttpClient) {
    super('absence/absenceRequest', _http);
  }

  get absence$(): Observable<any> {
    return this._absence.asObservable();
  }

  get absenceCount$() {
    return this._absenceCount;
  }

  getAll(filter: any): Observable<any[]> {
    return this.post(`dateWiseAbsenceList`, filter).pipe(
      tap((response) => {
        this._absenceCount = response.count;
        this._absence.next(response.result);
      })
    );
  }

  create(absence: any) {
    return this.post(`create`, absence).pipe(
      map((absenceResponse) => {
        return absenceResponse;
      })
    );
  }

  update(absence: any) {
    return this.post(`create`, absence).pipe(
      map((absenceResponse) => {
        return absenceResponse;
      })
    );
  }

  deleteCancelRequest(absenceId: any, reason: any) {
    const payload = {
      _id: absenceId,
      reason: reason,
    };
    return this.post(`deleteCancelRequest`, payload).pipe(
      map((absenceResponse) => {
        return absenceResponse;
      })
    );
  }

  approveRejectRequest(absenceId: any, action: any, reason: any) {
    const payload = {
      _id: absenceId,
      action: action,
      reason: reason,
    };
    return this.post(`approveRejectRequest`, payload).pipe(
      map((absenceResponse) => {
        return absenceResponse;
      })
    );
  }

  teamList(departmentId: any, positionId: any) {
    const payload = {
      departmentIds: departmentId,
      positionId: positionId,
    };
    return this.post(`userList`, payload).pipe(
      map((userList) => {
        return userList;
      })
    );
  }

  userLeaveBalanceDetail(userId: any) {
    return this.post(`userLeaveBalanceDetail`, { userId: userId }).pipe(
      map((details) => {
        return details;
      })
    );
  }

  departmentFilter(userId: any) {
    const payload = {
      userId: userId,
    };
    return this.post(`department-filterList`, payload);
  }

  getRequestDetails(absenceRequestId) {
    return this.get(`${absenceRequestId}/detail`);
  }
}
