import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VisionAppComponent } from '../../../../../core/base-classes/vision-app.component';
import { AppComponent } from '../../../../../app.component';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { STATICS } from '../../../../../core/config/static/static';
import { CalendarService } from '../calendar.service';
import { SupabaseService } from '../../../../../core/auth/supabase.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  styleUrls: ['./create-event.component.scss'],
})
export class CreateEventComponent extends VisionAppComponent implements OnInit {
  absenceCreationFrom: UntypedFormGroup;
  leaveTypes = [];
  absenceStatus = STATICS.ABSENCE_STATUS;
  public showSpinners = true;
  public showSeconds = false;
  public hideTime = false;
  public touchUi = false;
  public enableMeridian = true;
  public minDate = null;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public startDate = new FormControl(new Date());
  public endDate = new FormControl(new Date());
  public minEndDate = null;
  public absenceStatusList = [];
  public userLevel = {
    admin: false,
    hr: false,
    manager: false,
    employee: true,
  };
  public leaveDetails: any;
  constructor(
    @Inject(AppComponent) private appComponent: AppComponent,
    private _formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CreateEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private calendarService: CalendarService,
    private _supabaseService: SupabaseService
  ) {
    super(appComponent);
    this.leaveTypes = this.data?.absenceTypes;
  }
  ngOnInit(): void {
    if (this._supabaseService.hasPermissions(this.data?.currentUser.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.LEAVE_BALANCE)) {
      this.showLoader();
      this.calendarService.userLeaveBalanceDetail(this.data.selectedUser.userId).subscribe(
        (data) => {
          this.leaveDetails = data;
          this.hideLoader();
        },
        (err) => {
          this.hideLoader();
          this.showErrorToast(`Error in fetching available leaves ${err.error.message}`);
        }
      );
    } else {
      this.showErrorToast(STATICS.ERRORS.UNAUTHORIZED);
    }
    if (this.data.selectInfo?.view?.type === 'dayGridMonth') {
      this.data.selectInfo.end.setMinutes(this.data.selectInfo.end.getMinutes() - 330);
      this.data.selectInfo.start.setMinutes(this.data.selectInfo.start.getMinutes() + 600);
    }

    this.absenceStatusList = [];
    if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'HR', 'HR')) {
      this.userLevel.hr = true;
      for (let index = 0; index < STATICS.ABSENCE_STATUS.length; index++) {
        if (STATICS.ABSENCE_STATUS[index].status === 1 || STATICS.ABSENCE_STATUS[index].status === 7) {
          this.absenceStatusList.push(STATICS.ABSENCE_STATUS[index]);
        }
      }
    } else if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'Manager', 'Manager')) {
      this.userLevel.manager = true;
      for (let index = 0; index < STATICS.ABSENCE_STATUS.length; index++) {
        if (STATICS.ABSENCE_STATUS[index].status === 1 || STATICS.ABSENCE_STATUS[index].status === 5) {
          this.absenceStatusList.push(STATICS.ABSENCE_STATUS[index]);
        }
      }
    } else if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'Admin', 'Admin')) {
      this.userLevel.admin = true;
      for (let index = 0; index < STATICS.ABSENCE_STATUS.length; index++) {
        this.absenceStatusList.push(STATICS.ABSENCE_STATUS[index]);
      }
    } else {
      this.minDate = new Date();
    }
    this.absenceCreationFrom = this._formBuilder.group({
      titleId: [this.data?.selectInfo?.titleId || '', [Validators.required]],
      title: [this.data?.selectInfo?.title],
      allDay: [this.data?.selectInfo?.allDay],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      description: [this.data?.selectInfo?.description, Validators.required],
      deleteDescription: [''],
      absenceStatus: [this.data?.selectInfo?.status || ''],
      titleSearch: [''],
    });
    if (this.data?.selectInfo?.start) {
      this.startDate = new FormControl(new Date(this.data?.selectInfo?.start));
    }
    if (this.data?.selectInfo?.end) {
      this.endDate = new FormControl(new Date(this.data?.selectInfo?.end));
    }
    this.onChangeStartDate(new Date(this.data?.selectInfo?.start));
    this.onChangeEndDate(new Date(this.data?.selectInfo?.end));
  }

  onChangeStartDate(event) {
    this.minEndDate = event;
    this.absenceCreationFrom.controls['start'].setValue(new Date(event));
  }

  onChangeEndDate(event) {
    this.absenceCreationFrom.controls['end'].setValue(new Date(event));
  }

  onSubmit() {
    if (!this.absenceCreationFrom.invalid) {
      const eventValue = this.absenceCreationFrom.value;
      if (eventValue.start.getTime() < eventValue.end.getTime()) {
        this.showLoader();
        const data = {
          absenceTypeId: eventValue.titleId,
          companyId: this.data?.currentUser?.companyId,
          isFullDay: eventValue.allDay,
          startDate: new Date(eventValue.start),
          endDate: new Date(eventValue.end),
          reason: eventValue.description,
          userId: this.data?.selectedUser?.userId,
        };
        if (this.data.isManager) {
          data['absenceStatus'] = eventValue.absenceStatus;
        }
        this.calendarService.create(data).subscribe(
          (data) => {
            this.hideLoader();
            this.showSuccessToast(`Record Created!`);
            this.dialogRef.close({ status: true, record: data });
          },
          (err) => {
            this.hideLoader();
            this.showErrorToast(`Error in record creating ${err.error.message}`);
          }
        );
      } else {
        this.showWarningToast('Start date time should be less then end date');
      }
    } else if (this.absenceCreationFrom.invalid) {
      this.absenceCreationFrom.markAllAsTouched();
      this.showWarningToast(STATICS.ERRORS.FORMINVALID);
      return;
    }
  }

  filterOptionsByName(options, value) {
    value = value.trim().toLowerCase();
    return options.filter((option) => {
      return option.name.toLowerCase().includes(value);
    });
  }

  filtersItem(event) {
    this.leaveTypes = this.filterOptionsByName(this.data?.absenceTypes, event);
  }

  closeModel() {
    this.dialogRef.close({ status: false, record: null });
  }
}
