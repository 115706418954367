import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  @Input() rootModuleName = 'LEAD_STATUS.LIST.dashboard';
  @Input() moduleName = 'LEAD_STATUS.LIST.crm';
  @Input() componentName = 'LEAD_STATUS.LIST.leadMaster';

  constructor() {}

  ngOnInit() {}
}
