/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { STATICS } from '../../../core/config/static/static';
export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:dashboard',
    link: '/dashboards',
    children: [],
    path: '',
  },
  {
    id: 'generalMaster',
    title: 'General Masters',
    type: 'collapsable',
    modules: [STATICS.MODULES.COUNTRY, STATICS.MODULES.OFFICELOCATION, STATICS.MODULES.STATE, STATICS.MODULES.CITY],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        modules: [STATICS.MODULES.COUNTRY],
        id: 'generalMaster.masters.countries',
        title: 'Countries',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/countries',
        path: STATICS.APIS.COUNTRY.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.OFFICELOCATION],
        id: 'generalMaster.masters.officeLocation',
        title: 'Office Location',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/officeLocation',
        path: STATICS.APIS.OFFICELOCATION.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.STATE],
        id: 'generalMaster.masters.state',
        title: 'State',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/state',
        path: STATICS.APIS.STATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.CITY],
        id: 'generalMaster.masters.city',
        title: 'City',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/city',
        path: STATICS.APIS.CITY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'superAdmin',
    title: 'Super Admin',
    type: 'collapsable',
    modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE, STATICS.MODULES.COMPANY],
    icon: 'heroicons_outline:superAdmin',
    path: '',
    children: [
      {
        id: 'superAdmin.masters',
        title: 'Master',
        modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'superAdmin.masters.languages',
            title: 'Languages',
            modules: [STATICS.MODULES.LANGUAGE],
            type: 'basic',
            icon: 'heroicons_outline:language',
            link: '/languages',
            path: STATICS.APIS.LANGUAGE.LISTING,
            children: [],
          },
          {
            id: 'superAdmin.masters.companyTypes',
            modules: [STATICS.MODULES.COMPANYTYPE],
            title: 'Company Types',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/company-types',
            path: STATICS.APIS.COMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'superAdmin.companies',
        title: 'Tenants',
        modules: [STATICS.MODULES.COMPANY],
        type: 'basic',
        icon: 'heroicons_outline:tenant',
        link: '/companies',
        path: STATICS.APIS.COMPANY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'crm',
    title: 'CRM',
    modules: [
      STATICS.MODULES.LEAD,
      STATICS.MODULES.LEADSTATUS,
      STATICS.MODULES.CONTACTROLE,
      STATICS.MODULES.ACCOUNTSOURCE,
      STATICS.MODULES.BUSINESSTERRITORY,
      STATICS.MODULES.JOBTITLE,
      STATICS.MODULES.PRODUCTINTEREST,
      STATICS.MODULES.SUBBUSINESSTERRITORY,
      STATICS.MODULES.LEADDEPARTMENT,
      STATICS.MODULES.LEADCOMPANYTYPE,
    ],
    type: 'collapsable',
    icon: 'heroicons_outline:crm',
    path: '',
    children: [
      {
        id: 'crm.masters',
        title: 'Master',
        modules: [
          STATICS.MODULES.LEADSTATUS,
          STATICS.MODULES.CONTACTROLE,
          STATICS.MODULES.ACCOUNTSOURCE,
          STATICS.MODULES.BUSINESSTERRITORY,
          STATICS.MODULES.JOBTITLE,
          STATICS.MODULES.PRODUCTINTEREST,
          STATICS.MODULES.SUBBUSINESSTERRITORY,
          STATICS.MODULES.LEADDEPARTMENT,
          STATICS.MODULES.LEADCOMPANYTYPE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'crm.masters.leadStatus',
            title: 'Lead Status',
            modules: [STATICS.MODULES.LEADSTATUS],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-status',
            path: STATICS.APIS.LEADSTATUS.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.contactRole',
            title: 'Contact Role',
            modules: [STATICS.MODULES.CONTACTROLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/contact-role',
            path: STATICS.APIS.CONTACTROLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.accountSource',
            title: 'Lead Source',
            modules: [STATICS.MODULES.ACCOUNTSOURCE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-source',
            path: STATICS.APIS.ACCOUNTSOURCE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.businessTerritory',
            title: 'Business Territory',
            modules: [STATICS.MODULES.BUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/business-territory',
            path: STATICS.APIS.BUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.jobTitle',
            title: 'Job Title',
            modules: [STATICS.MODULES.JOBTITLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/job-title',
            path: STATICS.APIS.JOBTITLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.productInterest',
            title: 'Product Interest',
            modules: [STATICS.MODULES.PRODUCTINTEREST],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/product-interest',
            path: STATICS.APIS.PRODUCTINTEREST.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.subBusinessTerritory',
            title: 'Sub Business Territory',
            modules: [STATICS.MODULES.SUBBUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/subBusiness-territory',
            path: STATICS.APIS.SUBBUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadDepartment',
            title: 'Lead Department',
            modules: [STATICS.MODULES.LEADDEPARTMENT],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-department',
            path: STATICS.APIS.LEADDEPARTMENT.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadCompanyType',
            title: 'Lead CompanyType',
            modules: [STATICS.MODULES.LEADCOMPANYTYPE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-companyType',
            path: STATICS.APIS.LEADCOMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'crm.leadManage',
        title: 'Leads',
        modules: [STATICS.MODULES.LEAD],
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/leads',
        path: STATICS.APIS.LEAD.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'tenants',
    title: 'HR Masters',
    type: 'collapsable',
    // eslint-disable-next-line max-len
    modules: [
      STATICS.MODULES.BRANCH,
      STATICS.MODULES.DEPARTMENT,
      STATICS.MODULES.ROLE,
      STATICS.MODULES.POSITION,
      STATICS.MODULES.PAYMENTTYPE,
      STATICS.MODULES.CURRENCYTYPE,
      STATICS.MODULES.TAXMASTER,
      STATICS.MODULES.FINANCIALYEAR,
      STATICS.MODULES.COSTCENTER,
      STATICS.MODULES.CONTRACTTYPE,
      STATICS.MODULES.HEALTHINSURANCE,
      STATICS.MODULES.USER,
      STATICS.MODULES.USERTEMPLATE,
      STATICS.MODULES.EMPLOYEE,
      STATICS.MODULES.HR,
      STATICS.MODULES.MANAGER,
      STATICS.MODULES.ADMIN,
    ],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        id: 'tenants.masters',
        title: 'Masters',
        // eslint-disable-next-line max-len
        modules: [
          STATICS.MODULES.BRANCH,
          STATICS.MODULES.DEPARTMENT,
          STATICS.MODULES.ROLE,
          STATICS.MODULES.POSITION,
          STATICS.MODULES.PAYMENTTYPE,
          STATICS.MODULES.CURRENCYTYPE,
          STATICS.MODULES.TAXMASTER,
          STATICS.MODULES.FINANCIALYEAR,
          STATICS.MODULES.COSTCENTER,
          STATICS.MODULES.CONTRACTTYPE,
          STATICS.MODULES.HEALTHINSURANCE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.BRANCH],
            id: 'tenants.masters.branch',
            title: 'Branch',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/branch',
            path: STATICS.APIS.BRANCH.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.DEPARTMENT],
            id: 'tenants.masters.departments',
            title: 'Departments',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/departments',
            path: STATICS.APIS.DEPARTMENT.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.ROLE],
            id: 'tenants.masters.roles',
            title: 'Roles',
            type: 'basic',
            icon: 'heroicons_outline:role',
            link: '/tenants/roles',
            path: STATICS.APIS.ROLE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.PAYMENTTYPE],
            id: 'tenants.masters.paymentType',
            title: 'Payment Type',
            type: 'basic',
            icon: 'heroicons_outline:paymentType',
            link: '/tenants/payment-type',
            path: STATICS.APIS.PAYMENTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CURRENCYTYPE],
            id: 'tenants.masters.currencyMaster',
            title: 'Currency',
            type: 'basic',
            icon: 'heroicons_solid:currency-dollar',
            link: '/tenants/currency-type',
            path: STATICS.APIS.CURRENCYTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CONTRACTTYPE],
            id: 'tenants.masters.contracts',
            title: 'Contracts',
            type: 'basic',
            icon: 'heroicons_outline:contractType',
            link: '/tenants/contracts',
            path: STATICS.APIS.CONTRACTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.TAXMASTER],
            id: 'tenants.masters.taxClass',
            title: 'Tax Class',
            type: 'basic',
            icon: 'heroicons_outline:taxClass',
            link: '/tenants/tax-class',
            path: STATICS.APIS.TAXMASTER.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.HEALTHINSURANCE],
            id: 'tenants.masters.healthInsurance',
            title: 'Health Insurance',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/health-Insurance',
            path: STATICS.APIS.HEALTHINSURANCE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.FINANCIALYEAR],
            id: 'tenants.masters.financialYears',
            title: 'Financial Years',
            type: 'basic',
            icon: 'heroicons_outline:financialyear',
            link: '/tenants/financial-years',
            path: STATICS.APIS.FINANCIALYEAR.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.POSITION],
            id: 'tenants.masters.position',
            title: 'Position',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/position',
            path: STATICS.APIS.POSITION.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.COSTCENTER],
            id: 'tenants.masters.costCenter',
            title: 'Cost Center',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/cost-centers',
            path: STATICS.APIS.COSTCENTER.LISTING,
            children: [],
          },
        ],
      },
      {
        modules: [STATICS.MODULES.USERTEMPLATE],
        id: 'tenants.userProfile',
        title: 'User Template',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/userProfile',
        path: STATICS.APIS.USERTEMPLATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.USER],
        id: 'tenants.users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/users',
        path: STATICS.APIS.USER.LISTING,
        children: [],
      },
      {
        id: 'tenants.calendar',
        title: 'Calendar',
        type: 'collapsable',
        icon: 'heroicons_outline:calendar',
        modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.HR, STATICS.MODULES.MANAGER, STATICS.MODULES.ADMIN],
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.my-calendar',
            title: 'My Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/my-calendar',
            path: '',
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar',
            title: 'Team Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar',
            path: '',
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar-resource',
            title: 'Resource Scheduler',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar-resource',
            path: '',
            children: [],
          },
        ],
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:dashboard',
    link: '/dashboards',
    children: [],
    path: '',
  },
  {
    id: 'generalMaster',
    title: 'General Masters',
    type: 'collapsable',
    modules: [STATICS.MODULES.COUNTRY, STATICS.MODULES.OFFICELOCATION, STATICS.MODULES.STATE, STATICS.MODULES.CITY],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        modules: [STATICS.MODULES.COUNTRY],
        id: 'generalMaster.masters.countries',
        title: 'Countries',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/countries',
        path: STATICS.APIS.COUNTRY.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.OFFICELOCATION],
        id: 'generalMaster.masters.officeLocation',
        title: 'Office Location',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/officeLocation',
        path: STATICS.APIS.OFFICELOCATION.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.STATE],
        id: 'generalMaster.masters.state',
        title: 'State',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/state',
        path: STATICS.APIS.STATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.CITY],
        id: 'generalMaster.masters.city',
        title: 'City',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/city',
        path: STATICS.APIS.CITY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'superAdmin',
    title: 'Super Admin',
    type: 'collapsable',
    modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE, STATICS.MODULES.COMPANY],
    icon: 'heroicons_outline:superAdmin',
    path: '',
    children: [
      {
        id: 'superAdmin.masters',
        title: 'Master',
        modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'superAdmin.masters.languages',
            title: 'Languages',
            modules: [STATICS.MODULES.LANGUAGE],
            type: 'basic',
            icon: 'heroicons_outline:language',
            link: '/languages',
            path: STATICS.APIS.LANGUAGE.LISTING,
            children: [],
          },
          {
            id: 'superAdmin.masters.companyTypes',
            modules: [STATICS.MODULES.COMPANYTYPE],
            title: 'Company Types',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/company-types',
            path: STATICS.APIS.COMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'superAdmin.companies',
        title: 'Tenants',
        modules: [STATICS.MODULES.COMPANY],
        type: 'basic',
        icon: 'heroicons_outline:tenant',
        link: '/companies',
        path: STATICS.APIS.COMPANY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'crm',
    title: 'CRM',
    modules: [
      STATICS.MODULES.LEAD,
      STATICS.MODULES.LEADSTATUS,
      STATICS.MODULES.CONTACTROLE,
      STATICS.MODULES.ACCOUNTSOURCE,
      STATICS.MODULES.BUSINESSTERRITORY,
      STATICS.MODULES.JOBTITLE,
      STATICS.MODULES.PRODUCTINTEREST,
      STATICS.MODULES.SUBBUSINESSTERRITORY,
      STATICS.MODULES.LEADDEPARTMENT,
      STATICS.MODULES.LEADCOMPANYTYPE,
    ],
    type: 'collapsable',
    icon: 'heroicons_outline:crm',
    path: '',
    children: [
      {
        id: 'crm.masters',
        title: 'Master',
        modules: [
          STATICS.MODULES.LEADSTATUS,
          STATICS.MODULES.CONTACTROLE,
          STATICS.MODULES.ACCOUNTSOURCE,
          STATICS.MODULES.BUSINESSTERRITORY,
          STATICS.MODULES.JOBTITLE,
          STATICS.MODULES.PRODUCTINTEREST,
          STATICS.MODULES.SUBBUSINESSTERRITORY,
          STATICS.MODULES.LEADDEPARTMENT,
          STATICS.MODULES.LEADCOMPANYTYPE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'crm.masters.leadStatus',
            title: 'Lead Status',
            modules: [STATICS.MODULES.LEADSTATUS],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-status',
            path: STATICS.APIS.LEADSTATUS.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.contactRole',
            title: 'Contact Role',
            modules: [STATICS.MODULES.CONTACTROLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/contact-role',
            path: STATICS.APIS.CONTACTROLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.accountSource',
            title: 'Lead Source',
            modules: [STATICS.MODULES.ACCOUNTSOURCE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-source',
            path: STATICS.APIS.ACCOUNTSOURCE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.businessTerritory',
            title: 'Business Territory',
            modules: [STATICS.MODULES.BUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/business-territory',
            path: STATICS.APIS.BUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.jobTitle',
            title: 'Job Title',
            modules: [STATICS.MODULES.JOBTITLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/job-title',
            path: STATICS.APIS.JOBTITLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.productInterest',
            title: 'Product Interest',
            modules: [STATICS.MODULES.PRODUCTINTEREST],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/product-interest',
            path: STATICS.APIS.PRODUCTINTEREST.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.subBusinessTerritory',
            title: 'Sub Business Territory',
            modules: [STATICS.MODULES.SUBBUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/subBusiness-territory',
            path: STATICS.APIS.SUBBUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadDepartment',
            title: 'Lead Department',
            modules: [STATICS.MODULES.LEADDEPARTMENT],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-department',
            path: STATICS.APIS.LEADDEPARTMENT.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadCompanyType',
            title: 'Lead CompanyType',
            modules: [STATICS.MODULES.LEADCOMPANYTYPE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-companyType',
            path: STATICS.APIS.LEADCOMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'crm.leadManage',
        title: 'Leads',
        modules: [STATICS.MODULES.LEAD],
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/leads',
        path: STATICS.APIS.LEAD.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'tenants',
    title: 'HR Masters',
    type: 'collapsable',
    // eslint-disable-next-line max-len
    modules: [
      STATICS.MODULES.BRANCH,
      STATICS.MODULES.DEPARTMENT,
      STATICS.MODULES.ROLE,
      STATICS.MODULES.POSITION,
      STATICS.MODULES.PAYMENTTYPE,
      STATICS.MODULES.CURRENCYTYPE,
      STATICS.MODULES.TAXMASTER,
      STATICS.MODULES.FINANCIALYEAR,
      STATICS.MODULES.COSTCENTER,
      STATICS.MODULES.CONTRACTTYPE,
      STATICS.MODULES.HEALTHINSURANCE,
      STATICS.MODULES.USER,
      STATICS.MODULES.USERTEMPLATE,
      STATICS.MODULES.EMPLOYEE,
      STATICS.MODULES.HR,
      STATICS.MODULES.MANAGER,
      STATICS.MODULES.ADMIN,
    ],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        id: 'tenants.masters',
        title: 'Masters',
        // eslint-disable-next-line max-len
        modules: [
          STATICS.MODULES.BRANCH,
          STATICS.MODULES.DEPARTMENT,
          STATICS.MODULES.ROLE,
          STATICS.MODULES.POSITION,
          STATICS.MODULES.PAYMENTTYPE,
          STATICS.MODULES.CURRENCYTYPE,
          STATICS.MODULES.TAXMASTER,
          STATICS.MODULES.FINANCIALYEAR,
          STATICS.MODULES.COSTCENTER,
          STATICS.MODULES.CONTRACTTYPE,
          STATICS.MODULES.HEALTHINSURANCE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.BRANCH],
            id: 'tenants.masters.branch',
            title: 'Branch',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/branch',
            path: STATICS.APIS.BRANCH.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.DEPARTMENT],
            id: 'tenants.masters.departments',
            title: 'Departments',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/departments',
            path: STATICS.APIS.DEPARTMENT.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.ROLE],
            id: 'tenants.masters.roles',
            title: 'Roles',
            type: 'basic',
            icon: 'heroicons_outline:role',
            link: '/tenants/roles',
            path: STATICS.APIS.ROLE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.PAYMENTTYPE],
            id: 'tenants.masters.paymentType',
            title: 'Payment Type',
            type: 'basic',
            icon: 'heroicons_outline:paymentType',
            link: '/tenants/payment-type',
            path: STATICS.APIS.PAYMENTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CURRENCYTYPE],
            id: 'tenants.masters.currencyMaster',
            title: 'Currency',
            type: 'basic',
            icon: 'heroicons_solid:currency-dollar',
            link: '/tenants/currency-type',
            path: STATICS.APIS.CURRENCYTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CONTRACTTYPE],
            id: 'tenants.masters.contracts',
            title: 'Contracts',
            type: 'basic',
            icon: 'heroicons_outline:contractType',
            link: '/tenants/contracts',
            path: STATICS.APIS.CONTRACTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.TAXMASTER],
            id: 'tenants.masters.taxClass',
            title: 'Tax Class',
            type: 'basic',
            icon: 'heroicons_outline:taxClass',
            link: '/tenants/tax-class',
            path: STATICS.APIS.TAXMASTER.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.HEALTHINSURANCE],
            id: 'tenants.masters.healthInsurance',
            title: 'Health Insurance',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/health-Insurance',
            path: STATICS.APIS.HEALTHINSURANCE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.FINANCIALYEAR],
            id: 'tenants.masters.financialYears',
            title: 'Financial Years',
            type: 'basic',
            icon: 'heroicons_outline:financialyear',
            link: '/tenants/financial-years',
            path: STATICS.APIS.FINANCIALYEAR.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.POSITION],
            id: 'tenants.masters.position',
            title: 'Position',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/position',
            path: STATICS.APIS.POSITION.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.COSTCENTER],
            id: 'tenants.masters.costCenter',
            title: 'Cost Center',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/cost-centers',
            path: STATICS.APIS.COSTCENTER.LISTING,
            children: [],
          },
        ],
      },
      {
        modules: [STATICS.MODULES.USERTEMPLATE],
        id: 'tenants.userProfile',
        title: 'User Template',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/userProfile',
        path: STATICS.APIS.USERTEMPLATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.USER],
        id: 'tenants.users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/users',
        path: STATICS.APIS.USER.LISTING,
        children: [],
      },
      {
        id: 'tenants.calendar',
        title: 'Calendar',
        type: 'collapsable',
        icon: 'heroicons_outline:calendar',
        modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.HR, STATICS.MODULES.MANAGER, STATICS.MODULES.ADMIN],
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.my-calendar',
            title: 'My Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/my-calendar',
            path: '',
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar',
            title: 'Team Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar',
            path: '',
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar-resource',
            title: 'Resource Scheduler',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar-resource',
            path: '',
            children: [],
          },
        ],
      },
    ],
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:dashboard',
    link: '/dashboards',
    children: [],
    path: '',
  },
  {
    id: 'generalMaster',
    title: 'General Masters',
    type: 'collapsable',
    modules: [STATICS.MODULES.COUNTRY, STATICS.MODULES.OFFICELOCATION, STATICS.MODULES.STATE, STATICS.MODULES.CITY],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        modules: [STATICS.MODULES.COUNTRY],
        id: 'generalMaster.masters.countries',
        title: 'Countries',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/countries',
        path: STATICS.APIS.COUNTRY.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.OFFICELOCATION],
        id: 'generalMaster.masters.officeLocation',
        title: 'Office Location',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/officeLocation',
        path: STATICS.APIS.OFFICELOCATION.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.STATE],
        id: 'generalMaster.masters.state',
        title: 'State',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/state',
        path: STATICS.APIS.STATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.CITY],
        id: 'generalMaster.masters.city',
        title: 'City',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/city',
        path: STATICS.APIS.CITY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'superAdmin',
    title: 'Super Admin',
    type: 'collapsable',
    modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE, STATICS.MODULES.COMPANY],
    icon: 'heroicons_outline:superAdmin',
    path: '',
    children: [
      {
        id: 'superAdmin.masters',
        title: 'Master',
        modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'superAdmin.masters.languages',
            title: 'Languages',
            modules: [STATICS.MODULES.LANGUAGE],
            type: 'basic',
            icon: 'heroicons_outline:language',
            link: '/languages',
            path: STATICS.APIS.LANGUAGE.LISTING,
            children: [],
          },
          {
            id: 'superAdmin.masters.companyTypes',
            modules: [STATICS.MODULES.COMPANYTYPE],
            title: 'Company Types',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/company-types',
            path: STATICS.APIS.COMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'superAdmin.companies',
        title: 'Tenants',
        modules: [STATICS.MODULES.COMPANY],
        type: 'basic',
        icon: 'heroicons_outline:tenant',
        link: '/companies',
        path: STATICS.APIS.COMPANY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'crm',
    title: 'CRM',
    modules: [
      STATICS.MODULES.LEAD,
      STATICS.MODULES.LEADSTATUS,
      STATICS.MODULES.CONTACTROLE,
      STATICS.MODULES.ACCOUNTSOURCE,
      STATICS.MODULES.BUSINESSTERRITORY,
      STATICS.MODULES.JOBTITLE,
      STATICS.MODULES.PRODUCTINTEREST,
      STATICS.MODULES.SUBBUSINESSTERRITORY,
      STATICS.MODULES.LEADDEPARTMENT,
      STATICS.MODULES.LEADCOMPANYTYPE,
    ],
    type: 'collapsable',
    icon: 'heroicons_outline:crm',
    path: '',
    children: [
      {
        id: 'crm.masters',
        title: 'Master',
        modules: [
          STATICS.MODULES.LEADSTATUS,
          STATICS.MODULES.CONTACTROLE,
          STATICS.MODULES.ACCOUNTSOURCE,
          STATICS.MODULES.BUSINESSTERRITORY,
          STATICS.MODULES.JOBTITLE,
          STATICS.MODULES.PRODUCTINTEREST,
          STATICS.MODULES.SUBBUSINESSTERRITORY,
          STATICS.MODULES.LEADDEPARTMENT,
          STATICS.MODULES.LEADCOMPANYTYPE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'crm.masters.leadStatus',
            title: 'Lead Status',
            modules: [STATICS.MODULES.LEADSTATUS],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-status',
            path: STATICS.APIS.LEADSTATUS.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.contactRole',
            title: 'Contact Role',
            modules: [STATICS.MODULES.CONTACTROLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/contact-role',
            path: STATICS.APIS.CONTACTROLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.accountSource',
            title: 'Lead Source',
            modules: [STATICS.MODULES.ACCOUNTSOURCE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-source',
            path: STATICS.APIS.ACCOUNTSOURCE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.businessTerritory',
            title: 'Business Territory',
            modules: [STATICS.MODULES.BUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/business-territory',
            path: STATICS.APIS.BUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.jobTitle',
            title: 'Job Title',
            modules: [STATICS.MODULES.JOBTITLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/job-title',
            path: STATICS.APIS.JOBTITLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.productInterest',
            title: 'Product Interest',
            modules: [STATICS.MODULES.PRODUCTINTEREST],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/product-interest',
            path: STATICS.APIS.PRODUCTINTEREST.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.subBusinessTerritory',
            title: 'Sub Business Territory',
            modules: [STATICS.MODULES.SUBBUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/subBusiness-territory',
            path: STATICS.APIS.SUBBUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadDepartment',
            title: 'Lead Department',
            modules: [STATICS.MODULES.LEADDEPARTMENT],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-department',
            path: STATICS.APIS.LEADDEPARTMENT.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadCompanyType',
            title: 'Lead CompanyType',
            modules: [STATICS.MODULES.LEADCOMPANYTYPE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-companyType',
            path: STATICS.APIS.LEADCOMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'crm.leadManage',
        title: 'Leads',
        modules: [STATICS.MODULES.LEAD],
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/leads',
        path: STATICS.APIS.LEAD.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'tenants',
    title: 'HR Masters',
    type: 'collapsable',
    // eslint-disable-next-line max-len
    modules: [
      STATICS.MODULES.BRANCH,
      STATICS.MODULES.DEPARTMENT,
      STATICS.MODULES.ROLE,
      STATICS.MODULES.POSITION,
      STATICS.MODULES.PAYMENTTYPE,
      STATICS.MODULES.CURRENCYTYPE,
      STATICS.MODULES.TAXMASTER,
      STATICS.MODULES.FINANCIALYEAR,
      STATICS.MODULES.COSTCENTER,
      STATICS.MODULES.CONTRACTTYPE,
      STATICS.MODULES.HEALTHINSURANCE,
      STATICS.MODULES.USER,
      STATICS.MODULES.USERTEMPLATE,
      STATICS.MODULES.EMPLOYEE,
      STATICS.MODULES.HR,
      STATICS.MODULES.MANAGER,
      STATICS.MODULES.ADMIN,
    ],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        id: 'tenants.masters',
        title: 'Masters',
        // eslint-disable-next-line max-len
        modules: [
          STATICS.MODULES.BRANCH,
          STATICS.MODULES.DEPARTMENT,
          STATICS.MODULES.ROLE,
          STATICS.MODULES.POSITION,
          STATICS.MODULES.PAYMENTTYPE,
          STATICS.MODULES.CURRENCYTYPE,
          STATICS.MODULES.TAXMASTER,
          STATICS.MODULES.FINANCIALYEAR,
          STATICS.MODULES.COSTCENTER,
          STATICS.MODULES.CONTRACTTYPE,
          STATICS.MODULES.HEALTHINSURANCE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.BRANCH],
            id: 'tenants.masters.branch',
            title: 'Branch',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/branch',
            path: STATICS.APIS.BRANCH.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.DEPARTMENT],
            id: 'tenants.masters.departments',
            title: 'Departments',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/departments',
            path: STATICS.APIS.DEPARTMENT.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.ROLE],
            id: 'tenants.masters.roles',
            title: 'Roles',
            type: 'basic',
            icon: 'heroicons_outline:role',
            link: '/tenants/roles',
            path: STATICS.APIS.ROLE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.PAYMENTTYPE],
            id: 'tenants.masters.paymentType',
            title: 'Payment Type',
            type: 'basic',
            icon: 'heroicons_outline:paymentType',
            link: '/tenants/payment-type',
            path: STATICS.APIS.PAYMENTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CURRENCYTYPE],
            id: 'tenants.masters.currencyMaster',
            title: 'Currency',
            type: 'basic',
            icon: 'heroicons_solid:currency-dollar',
            link: '/tenants/currency-type',
            path: STATICS.APIS.CURRENCYTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CONTRACTTYPE],
            id: 'tenants.masters.contracts',
            title: 'Contracts',
            type: 'basic',
            icon: 'heroicons_outline:contractType',
            link: '/tenants/contracts',
            path: STATICS.APIS.CONTRACTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.TAXMASTER],
            id: 'tenants.masters.taxClass',
            title: 'Tax Class',
            type: 'basic',
            icon: 'heroicons_outline:taxClass',
            link: '/tenants/tax-class',
            path: STATICS.APIS.TAXMASTER.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.HEALTHINSURANCE],
            id: 'tenants.masters.healthInsurance',
            title: 'Health Insurance',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/health-Insurance',
            path: STATICS.APIS.HEALTHINSURANCE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.FINANCIALYEAR],
            id: 'tenants.masters.financialYears',
            title: 'Financial Years',
            type: 'basic',
            icon: 'heroicons_outline:financialyear',
            link: '/tenants/financial-years',
            path: STATICS.APIS.FINANCIALYEAR.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.POSITION],
            id: 'tenants.masters.position',
            title: 'Position',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/position',
            path: STATICS.APIS.POSITION.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.COSTCENTER],
            id: 'tenants.masters.costCenter',
            title: 'Cost Center',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/cost-centers',
            path: STATICS.APIS.COSTCENTER.LISTING,
            children: [],
          },
        ],
      },
      {
        modules: [STATICS.MODULES.USERTEMPLATE],
        id: 'tenants.userProfile',
        title: 'User Template',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/userProfile',
        path: STATICS.APIS.USERTEMPLATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.USER],
        id: 'tenants.users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/users',
        path: STATICS.APIS.USER.LISTING,
        children: [],
      },
      {
        id: 'tenants.calendar',
        title: 'Calendar',
        type: 'collapsable',
        icon: 'heroicons_outline:calendar',
        modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.HR, STATICS.MODULES.MANAGER, STATICS.MODULES.ADMIN],
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.my-calendar',
            title: 'My Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/my-calendar',
            path: STATICS.APIS.ABSENCE_REQUEST.REQUEST_LIST,
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar',
            title: 'Team Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar',
            path: STATICS.APIS.ABSENCE_REQUEST.REQUEST_LIST,
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar-resource',
            title: 'Resource Scheduler',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar-resource',
            path: STATICS.APIS.ABSENCE_REQUEST.REQUEST_LIST,
            children: [],
          },
        ],
      },
    ],
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:dashboard',
    link: '/dashboards',
    children: [],
    path: '',
  },
  {
    id: 'generalMaster',
    title: 'General Masters',
    type: 'collapsable',
    modules: [STATICS.MODULES.COUNTRY, STATICS.MODULES.OFFICELOCATION, STATICS.MODULES.STATE, STATICS.MODULES.CITY],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        modules: [STATICS.MODULES.COUNTRY],
        id: 'generalMaster.masters.countries',
        title: 'Countries',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/countries',
        path: STATICS.APIS.COUNTRY.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.OFFICELOCATION],
        id: 'generalMaster.masters.officeLocation',
        title: 'Office Location',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/officeLocation',
        path: STATICS.APIS.OFFICELOCATION.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.STATE],
        id: 'generalMaster.masters.state',
        title: 'State',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/state',
        path: STATICS.APIS.STATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.CITY],
        id: 'generalMaster.masters.city',
        title: 'City',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/city',
        path: STATICS.APIS.CITY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'superAdmin',
    title: 'Super Admin',
    type: 'collapsable',
    modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE, STATICS.MODULES.COMPANY],
    icon: 'heroicons_outline:superAdmin',
    path: '',
    children: [
      {
        id: 'superAdmin.masters',
        title: 'Master',
        modules: [STATICS.MODULES.LANGUAGE, STATICS.MODULES.COMPANYTYPE],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'superAdmin.masters.languages',
            title: 'Languages',
            modules: [STATICS.MODULES.LANGUAGE],
            type: 'basic',
            icon: 'heroicons_outline:language',
            link: '/languages',
            path: STATICS.APIS.LANGUAGE.LISTING,
            children: [],
          },
          {
            id: 'superAdmin.masters.companyTypes',
            modules: [STATICS.MODULES.COMPANYTYPE],
            title: 'Company Types',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/company-types',
            path: STATICS.APIS.COMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'superAdmin.companies',
        title: 'Tenants',
        modules: [STATICS.MODULES.COMPANY],
        type: 'basic',
        icon: 'heroicons_outline:tenant',
        link: '/companies',
        path: STATICS.APIS.COMPANY.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'crm',
    title: 'CRM',
    modules: [
      STATICS.MODULES.LEAD,
      STATICS.MODULES.LEADSTATUS,
      STATICS.MODULES.CONTACTROLE,
      STATICS.MODULES.ACCOUNTSOURCE,
      STATICS.MODULES.BUSINESSTERRITORY,
      STATICS.MODULES.JOBTITLE,
      STATICS.MODULES.PRODUCTINTEREST,
      STATICS.MODULES.SUBBUSINESSTERRITORY,
      STATICS.MODULES.LEADDEPARTMENT,
      STATICS.MODULES.LEADCOMPANYTYPE,
    ],
    type: 'collapsable',
    icon: 'heroicons_outline:crm',
    path: '',
    children: [
      {
        id: 'crm.masters',
        title: 'Master',
        modules: [
          STATICS.MODULES.LEADSTATUS,
          STATICS.MODULES.CONTACTROLE,
          STATICS.MODULES.ACCOUNTSOURCE,
          STATICS.MODULES.BUSINESSTERRITORY,
          STATICS.MODULES.JOBTITLE,
          STATICS.MODULES.PRODUCTINTEREST,
          STATICS.MODULES.SUBBUSINESSTERRITORY,
          STATICS.MODULES.LEADDEPARTMENT,
          STATICS.MODULES.LEADCOMPANYTYPE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:clipboard-check',
        path: '',
        children: [
          {
            id: 'crm.masters.leadStatus',
            title: 'Lead Status',
            modules: [STATICS.MODULES.LEADSTATUS],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-status',
            path: STATICS.APIS.LEADSTATUS.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.contactRole',
            title: 'Contact Role',
            modules: [STATICS.MODULES.CONTACTROLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/contact-role',
            path: STATICS.APIS.CONTACTROLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.accountSource',
            title: 'Lead Source',
            modules: [STATICS.MODULES.ACCOUNTSOURCE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-source',
            path: STATICS.APIS.ACCOUNTSOURCE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.businessTerritory',
            title: 'Business Territory',
            modules: [STATICS.MODULES.BUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/business-territory',
            path: STATICS.APIS.BUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.jobTitle',
            title: 'Job Title',
            modules: [STATICS.MODULES.JOBTITLE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/job-title',
            path: STATICS.APIS.JOBTITLE.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.productInterest',
            title: 'Product Interest',
            modules: [STATICS.MODULES.PRODUCTINTEREST],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/product-interest',
            path: STATICS.APIS.PRODUCTINTEREST.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.subBusinessTerritory',
            title: 'Sub Business Territory',
            modules: [STATICS.MODULES.SUBBUSINESSTERRITORY],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/subBusiness-territory',
            path: STATICS.APIS.SUBBUSINESSTERRITORY.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadDepartment',
            title: 'Lead Department',
            modules: [STATICS.MODULES.LEADDEPARTMENT],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-department',
            path: STATICS.APIS.LEADDEPARTMENT.LISTING,
            children: [],
          },
          {
            id: 'crm.masters.leadCompanyType',
            title: 'Lead CompanyType',
            modules: [STATICS.MODULES.LEADCOMPANYTYPE],
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/lead-companyType',
            path: STATICS.APIS.LEADCOMPANYTYPE.LISTING,
            children: [],
          },
        ],
      },
      {
        id: 'crm.leadManage',
        title: 'Leads',
        modules: [STATICS.MODULES.LEAD],
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/leads',
        path: STATICS.APIS.LEAD.LISTING,
        children: [],
      },
    ],
  },
  {
    id: 'tenants',
    title: 'HR Masters',
    type: 'collapsable',
    // eslint-disable-next-line max-len
    modules: [
      STATICS.MODULES.BRANCH,
      STATICS.MODULES.DEPARTMENT,
      STATICS.MODULES.ROLE,
      STATICS.MODULES.POSITION,
      STATICS.MODULES.PAYMENTTYPE,
      STATICS.MODULES.CURRENCYTYPE,
      STATICS.MODULES.TAXMASTER,
      STATICS.MODULES.FINANCIALYEAR,
      STATICS.MODULES.COSTCENTER,
      STATICS.MODULES.CONTRACTTYPE,
      STATICS.MODULES.HEALTHINSURANCE,
      STATICS.MODULES.USER,
      STATICS.MODULES.USERTEMPLATE,
      STATICS.MODULES.EMPLOYEE,
      STATICS.MODULES.HR,
      STATICS.MODULES.MANAGER,
      STATICS.MODULES.ADMIN,
    ],
    icon: 'heroicons_outline:hrmaster',
    path: '',
    children: [
      {
        id: 'tenants.masters',
        title: 'Masters',
        // eslint-disable-next-line max-len
        modules: [
          STATICS.MODULES.BRANCH,
          STATICS.MODULES.DEPARTMENT,
          STATICS.MODULES.ROLE,
          STATICS.MODULES.POSITION,
          STATICS.MODULES.PAYMENTTYPE,
          STATICS.MODULES.CURRENCYTYPE,
          STATICS.MODULES.TAXMASTER,
          STATICS.MODULES.FINANCIALYEAR,
          STATICS.MODULES.COSTCENTER,
          STATICS.MODULES.CONTRACTTYPE,
          STATICS.MODULES.HEALTHINSURANCE,
        ],
        type: 'collapsable',
        icon: 'heroicons_outline:home',
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.BRANCH],
            id: 'tenants.masters.branch',
            title: 'Branch',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/branch',
            path: STATICS.APIS.BRANCH.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.DEPARTMENT],
            id: 'tenants.masters.departments',
            title: 'Departments',
            type: 'basic',
            icon: 'heroicons_outline:department',
            link: '/tenants/departments',
            path: STATICS.APIS.DEPARTMENT.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.ROLE],
            id: 'tenants.masters.roles',
            title: 'Roles',
            type: 'basic',
            icon: 'heroicons_outline:role',
            link: '/tenants/roles',
            path: STATICS.APIS.ROLE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.PAYMENTTYPE],
            id: 'tenants.masters.paymentType',
            title: 'Payment Type',
            type: 'basic',
            icon: 'heroicons_outline:paymentType',
            link: '/tenants/payment-type',
            path: STATICS.APIS.PAYMENTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CURRENCYTYPE],
            id: 'tenants.masters.currencyMaster',
            title: 'Currency',
            type: 'basic',
            icon: 'heroicons_solid:currency-dollar',
            link: '/tenants/currency-type',
            path: STATICS.APIS.CURRENCYTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.CONTRACTTYPE],
            id: 'tenants.masters.contracts',
            title: 'Contracts',
            type: 'basic',
            icon: 'heroicons_outline:contractType',
            link: '/tenants/contracts',
            path: STATICS.APIS.CONTRACTTYPE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.TAXMASTER],
            id: 'tenants.masters.taxClass',
            title: 'Tax Class',
            type: 'basic',
            icon: 'heroicons_outline:taxClass',
            link: '/tenants/tax-class',
            path: STATICS.APIS.TAXMASTER.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.HEALTHINSURANCE],
            id: 'tenants.masters.healthInsurance',
            title: 'Health Insurance',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/health-Insurance',
            path: STATICS.APIS.HEALTHINSURANCE.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.FINANCIALYEAR],
            id: 'tenants.masters.financialYears',
            title: 'Financial Years',
            type: 'basic',
            icon: 'heroicons_outline:financialyear',
            link: '/tenants/financial-years',
            path: STATICS.APIS.FINANCIALYEAR.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.POSITION],
            id: 'tenants.masters.position',
            title: 'Position',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/position',
            path: STATICS.APIS.POSITION.LISTING,
            children: [],
          },
          {
            modules: [STATICS.MODULES.COSTCENTER],
            id: 'tenants.masters.costCenter',
            title: 'Cost Center',
            type: 'basic',
            icon: 'heroicons_outline:clipboard-check',
            link: '/tenants/cost-centers',
            path: STATICS.APIS.COSTCENTER.LISTING,
            children: [],
          },
        ],
      },
      {
        modules: [STATICS.MODULES.USERTEMPLATE],
        id: 'tenants.userProfile',
        title: 'User Template',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/userProfile',
        path: STATICS.APIS.USERTEMPLATE.LISTING,
        children: [],
      },
      {
        modules: [STATICS.MODULES.USER],
        id: 'tenants.users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/tenants/users',
        path: STATICS.APIS.USER.LISTING,
        children: [],
      },
      {
        id: 'tenants.calendar',
        title: 'Calendar',
        type: 'collapsable',
        icon: 'heroicons_outline:calendar',
        modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.HR, STATICS.MODULES.MANAGER, STATICS.MODULES.ADMIN],
        path: '',
        children: [
          {
            modules: [STATICS.MODULES.EMPLOYEE, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.my-calendar',
            title: 'My Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/my-calendar',
            path: '',
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar',
            title: 'Team Calendar',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar',
            path: '',
            children: [],
          },
          {
            modules: [STATICS.MODULES.MANAGER, STATICS.MODULES.HR, STATICS.MODULES.ADMIN],
            id: 'tenants.calendar.team-calendar-resource',
            title: 'Resource Scheduler',
            type: 'basic',
            icon: 'heroicons_outline:users',
            link: '/tenants/calendar/team-calendar-resource',
            path: '',
            children: [],
          },
        ],
      },
    ],
  },
];
