import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  protected baseURL: string;

  constructor(baseURL: string, protected http: HttpClient) {
    this.baseURL = baseURL;
  }

  post(route = '', postData = {}, headers = {}) {
    return this.http.post<any>(`${environment.API_URL}/${this.baseURL}/${route}`, postData, headers);
  }

  put(route = '', putData = {}) {
    return this.http.put<any>(`${environment.API_URL}/${this.baseURL}/${route}`, putData);
  }

  get(route, params?) {
    let url = `${environment.API_URL}/${this.baseURL}/`;
    if (route === '') {
      url = `${environment.API_URL}/${this.baseURL}`;
    }
    return this.http.get<any>(`${url}${route}`, { params });
  }

  getResourceFromServer(route, params?) {
    let url = `${environment.API_URL}/${this.baseURL}/`;
    if (route === '') {
      url = `${environment.API_URL}/${this.baseURL}`;
    }
    return this.http.get(`${url}${route}`, { params });
  }
}
