import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appTrimText]',
})
export class TrimTextDirective {
  @Output() appTrimTextChange = new EventEmitter<string>();

  constructor(private el: ElementRef) {}

  @HostListener('focusout', ['$event.target.value'])
  public onKeyUp(value: string): void {
    const trimmedValue = value.trim();
    this.el.nativeElement.value = trimmedValue;
    this.appTrimTextChange.emit(trimmedValue);
    const inputEvent = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(inputEvent);
  }
}
