import { Route } from '@angular/router';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
import { TeamCalendarComponent } from './team-calendar/team-calendar.component';
import { TeamCalendarResourceComponent } from './team-calendar-resource/team-calendar-resource.component';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
export const CalendarRoutes: Route[] = [
  {
    canMatch: [AuthGuard],
    path: 'my-calendar',
    component: MyCalendarComponent,
  },
  {
    canMatch: [AuthGuard],
    path: 'team-calendar',
    component: TeamCalendarComponent,
  },
  {
    canMatch: [AuthGuard],
    path: 'team-calendar-resource',
    component: TeamCalendarResourceComponent,
  },
];
