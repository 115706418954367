import { Component, OnInit, Inject } from '@angular/core';
import { BranchService } from '../../../modules/admin/hr-masters/branch/branch.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { VisionAppComponent } from '../../../core/base-classes/vision-app.component';
import { AppComponent } from '../../../app.component';
import { Constants } from '../../../modules/admin/hr-masters/roles/permissions/constants';
import { SupabaseService } from '../../../core/auth/supabase.service';
@Component({
  selector: 'branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.scss'],
})
export class BranchDetailsComponent extends VisionAppComponent implements OnInit {
  record: any;
  detailsFields: any;

  constructor(
    @Inject(AppComponent) private appComponent: AppComponent,
    private _branchService: BranchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BranchDetailsComponent>,
    private _spinner: NgxSpinnerService,
    private _supabaseService: SupabaseService
  ) {
    super(appComponent);
    this.detailsFields = {};
    for (let index = 0; index < Constants.branchMaster.details.length; index++) {
      const res = this._supabaseService.hasFieldPermissions(
        this.data?.currentUser?.permission,
        'Branch',
        '/companies/branch-detail',
        Constants.branchMaster.details[index].field
      );
      if (res) {
        this.detailsFields[Constants.branchMaster.details[index].field] = res;
      } else {
        this.detailsFields[Constants.branchMaster.details[index].field] = Constants.branchMaster.details[index];
      }
    }
  }
  ngOnInit(): void {
    this.showLoader();
    this._branchService.getRecord(this.data.record._id).subscribe(
      (data) => {
        this.record = data.data;
        this.hideLoader();
      },
      (err) => {
        this.showErrorToast(`Error in fetching user details ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  viewAccess(fields) {
    const fieldsArray = fields.split(',');
    let returnValue = false;
    for (let index = 0; index < fieldsArray.length; index++) {
      if (this.detailsFields[fieldsArray[index]].read) {
        returnValue = true;
        break;
      }
    }
    return returnValue;
  }
}
