import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

//Base service
import { BaseApiService } from '../../../../shared/services/API-service/base-service';
@Injectable({ providedIn: 'root' })
export class AbsenceTypeService extends BaseApiService {
  private _absenceType: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _absenceTypeCount: number;
  constructor(private _http: HttpClient) {
    super('absence/absenceType', _http);
  }

  get absenceType$(): Observable<any> {
    return this._absenceType.asObservable();
  }
  get absenceTypeCount$() {
    return this._absenceTypeCount;
  }

  getAll(filter: any): Observable<any[]> {
    return this.post(`listing`, filter).pipe(
      tap((response) => {
        this._absenceTypeCount = response.count;
        this._absenceType.next(response.result);
      })
    );
  }

  create(absenceType: any) {
    return this.post(`create`, absenceType).pipe(
      map((newAbsenceType) => {
        return newAbsenceType;
      })
    );
  }

  update(absenceType: any) {
    return this.post(`update`, absenceType).pipe(
      map((updatedAbsenceType) => {
        return updatedAbsenceType;
      })
    );
  }

  filterList() {
    return this.post(`filter-list`).pipe(
      map((absenceTypes) => {
        return absenceTypes;
      })
    );
  }
}
