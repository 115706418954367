import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Router } from '@angular/router';
import { AppComponent } from '../../../../../app.component';
import { RecordsListComponent } from 'app/core/base-classes/records-list-component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CreateEventComponent } from '../create-event/create-event.component';
import { UpdateEventComponent } from '../update-event/update-event.component';
import { SupabaseService } from '../../../../../core/auth/supabase.service';
import { CalendarService } from '../calendar.service';
import { AbsenceTypeService } from '../../absence-type/absence-type.service';
import { Subject, takeUntil } from 'rxjs';
import { STATICS } from '../../../../../core/config/static/static';
import { TenantMasterService } from 'app/shared/services/API-service/tenant-master.service';

@Component({
  selector: 'app-team-calendar',
  templateUrl: './team-calendar.component.html',
  styleUrls: ['./team-calendar.component.scss'],
})
export class TeamCalendarComponent extends RecordsListComponent<any> {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  calendarVisible = true;
  eventsArray = [];
  absenceTypes = [];
  recordFilter: any;
  userList = [];
  selectedUser: any;
  drawerOpened = true;
  drawerMode: 'over' | 'side' = 'side';
  departmentList;
  filterDepartmentOption = [];

  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },

    buttonText: {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'List',
    },
    views: {
      multiMonthFourMonth: {
        type: 'multiMonth',
        duration: { months: 1 },
      },
    },
    initialView: 'dayGridMonth',
    // selectConstraint: {
    //   start: new Date().setDate(new Date().getDate() - 1),
    // },
    // eventConstraint: {
    //   start: new Date().setDate(new Date().getDate() - 1),
    // },
    initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
    editable: true,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: true,
    eventStartEditable: false,
    droppable: false,
    weekends: true,
    firstDay: 1,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    datesSet: this.handleMonthChange.bind(this),
  };

  // For Company User
  usersList: [];
  selectedDepartment = [];
  currentEvents: EventApi[] = [];
  currentUser: any;
  userId: any;
  constructor(
    private matDialog: MatDialog,
    @Inject(AppComponent) private appComponent: AppComponent,
    private _supabaseService: SupabaseService,
    private calendarService: CalendarService,
    private absenceTypeService: AbsenceTypeService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _tenantMasterService: TenantMasterService,
    private _router: Router
  ) {
    super(appComponent);
    this.recordFilter = {};
    this._supabaseService.getCurrentUser$.pipe().subscribe((user: any) => {
      this.currentUser = user;
      if (this._supabaseService.hasPermissions(this.currentUser.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.REQUEST_LIST)) {
        // eslint-disable-next-line max-len
        if (
          this._supabaseService.hasPermissions(
            this.currentUser.permission,
            STATICS.MODULES.ABSENCE_REQUEST,
            STATICS.APIS.ABSENCE_REQUEST.DEPARTMENT_FILTER_LIST
          )
        ) {
          this.loadMaster();
        }
      } else {
        this._router.navigate(['/404-not-found']);
        this.showWarningToast(STATICS.ERRORS.UNAUTHORIZED);
      }
    });
  }

  public getRecordsList() {
    // For Get Event
    this.showLoader();
    this.calendarService.getAll(this.recordFilter).subscribe(
      () => {
        this.calendarService.absence$.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
          this.recordsList = response;
          this.totalCount = this.calendarService.absenceCount$;
          this.eventsArray = [];
          if (this.recordsList.length > 0) {
            for (let index = 0; index < this.recordsList.length; index++) {
              let endDate = new Date(this.recordsList[index].endDate);
              if (this.recordsList[index].isFullDay) {
                endDate = new Date(endDate.setUTCHours(23, 59, 59, 999));
              }
              this.eventsArray.push({
                id: this.recordsList[index]._id,
                userId: this.recordsList[index].userId,
                createdBy: this.recordsList[index].createdBy,
                status: this.recordsList[index].absenceStatus,
                allDay: this.recordsList[index].isFullDay,
                title: this.recordsList[index].absenceTypeName,
                titleId: this.recordsList[index].absenceTypeId,
                start: this.recordsList[index].startDate,
                end: endDate,
                actualEndDate: this.recordsList[index].endDate,
                backgroundColor: STATICS.ABSENCE_STATUS.filter((status) => status.status === this.recordsList[index].absenceStatus)[0].color,
                description: this.recordsList[index].reason,
                rejectedReason: this.recordsList[index].rejectedReason,
                reason: this.recordsList[index].reason,
                managerExists: this.recordsList[index].managerExists,
                logs: this.recordsList[index]?.logs,
              });
            }
          }
          this.handleEvents();
          this.hideLoader();
        });
      },
      (err) => {
        this.showErrorToast(`Error in fetching records ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.openEventModal(selectInfo);
  }

  handleEventClick(clickInfo: EventClickArg) {
    // TODO For Get Event Using Event ID
    const result = this.eventsArray.filter((event) => event.id === clickInfo.event._def.publicId);
    if (result.length > 0) {
      this.openEventModal(result[0]);
    } else {
      this.showErrorToast('Event not exist');
    }
  }

  handleEvents() {
    this.calendarOptions.events = this.eventsArray;
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  handleMonthChange(payload) {
    this.recordFilter['startDate'] = new Date(payload.start);
    this.recordFilter['endDate'] = new Date(payload.end);
    if (this.selectedUser !== undefined && this.selectedUser !== null) {
      this.recordFilter.userId = this.selectedUser.userId;
      this.getRecordsList();
    }
  }

  openEventModal(eventInfo) {
    if (this._supabaseService.hasPermissions(this.currentUser.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.CREATE)) {
      if (!eventInfo.id) {
        const dialogConfig: MatDialogConfig = {
          disableClose: true,
          width: '60%',
          height: '80%',
          data: {
            selectInfo: eventInfo,
            currentUser: this.currentUser,
            absenceTypes: this.absenceTypes,
            selectedUser: this.selectedUser,
            isManager: true,
          },
          panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
        };
        const dialogRef: MatDialogRef<CreateEventComponent, any> = this.matDialog.open(CreateEventComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((response: any) => {
          if (response.status) {
            this.getRecordsList();
          }
        });
      } else {
        const dialogConfig: MatDialogConfig = {
          disableClose: true,
          width: '60%',
          height: '80%',
          data: {
            selectInfo: eventInfo,
            currentUser: this.currentUser,
            absenceTypes: this.absenceTypes,
            selectedUser: this.selectedUser,
            isManager: true,
          },
          panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
        };
        const dialogRef: MatDialogRef<UpdateEventComponent, any> = this.matDialog.open(UpdateEventComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((response: any) => {
          if (response.status) {
            this.getRecordsList();
          }
        });
      }
    } else {
      this.showErrorToast(STATICS.ERRORS.UNAUTHORIZED);
    }
  }

  changeUser(user) {
    this.recordFilter.userId = user.userId;
    this.selectedUser = user;
    this.getRecordsList();
  }

  filterUser(value) {
    this.userList = this.filterOptionsByName(this.usersList, value);
  }

  filterOptionsByName(options, value) {
    value = value.trim().toLowerCase();
    return options.filter((option) => {
      return option.firstName.toLowerCase().includes(value) || option.lastName.toLowerCase().includes(value);
    });
  }

  filterOptionsByDepartment(options, value) {
    if (value !== null) {
      value = value.trim().toLowerCase();
      return options.filter((option) => {
        return option.name.toLowerCase().includes(value);
      });
    }
  }

  loadMaster() {
    this.showLoader();
    this.calendarService.departmentFilter(this.currentUser.userId).subscribe(
      (data) => {
        this.filterDepartmentOption = data;
        if (this.filterDepartmentOption.length > 0) {
          // Loop through the filterDepartmentOption array and store IDs
          const departmentIds = [];
          for (const department of this.filterDepartmentOption) {
            departmentIds.push(department._id);
          }
          this.selectedDepartment = departmentIds;
          this.calendarService.teamList(departmentIds, this.currentUser.positionId).subscribe(
            (data) => {
              this.usersList = data.result;
              this.userList = data.result;
              if (this.userList.length > 0) {
                this.selectedUser = this.userList[0];
                this.absenceTypeService.filterList().subscribe(
                  (data) => {
                    this.absenceTypes = data;
                    this.recordFilter['userId'] = this.selectedUser.userId;
                    this.getRecordsList();
                  },
                  (err) => {
                    this.hideLoader();
                    this.showWarningToast(`Error in fetching record ${err.error.message}`);
                  }
                );
              } else {
                this.hideLoader();
                this.showWarningToast(`You have no team members`);
              }
            },
            (err) => {
              this.hideLoader();
              this.showWarningToast(`Error in fetching record ${err.error.message}`);
            }
          );
        } else {
          this.hideLoader();
        }
      },
      (err) => {
        this.hideLoader();
        this.showWarningToast(`Error in fetching record ${err.error.message}`);
      }
    );
  }

  filterTags(event, field) {
    if (field === 'department') {
      this.filterDepartmentOption = this.filterOptionsByDepartment(this._tenantMasterService.departmentList$, event);
    }
  }

  public searchCustomByValue(value) {
    if (value.length === 0) {
      this.showErrorToast('Please Select Minimum One Department');
      return;
    }
    this.showLoader();
    this.calendarService.teamList(value, this.currentUser.positionId).subscribe(
      (data) => {
        this.usersList = data.result;
        this.userList = data.result;
        if (this.userList.length > 0) {
          this.selectedUser = this.userList[0];
          this.recordFilter['userId'] = this.selectedUser.userId;
          this.getRecordsList();
        } else {
          this.showWarningToast(`You have no team members`);
          this.hideLoader();
        }
      },
      (err) => {
        this.hideLoader();
        this.showErrorToast(`Error in record updating ${err.error.message}`);
      }
    );
  }
}
