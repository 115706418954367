<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden" *ngIf="userList && userList.length > 0">
  <mat-drawer-container class="flex-auto h-full">
    <mat-drawer-content class="flex overflow-hidden">
      <ng-container>
        <div class="relative flex flex-auto flex-col w-full min-w-0 lg:min-w-80 lg:max-w-80 bg-card dark:bg-transparent">
          <div class="flex flex-col flex-0 py-4 px-8 border-b bg-gray-50 dark:bg-transparent">
            <div class="mt-4">
              <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded fuse-mat-dense w-full" [floatLabel]="'always'">
                <mat-icon matPrefix class="icon-size-5" [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [autocomplete]="'off'" [placeholder]="'Search User By Name'" (input)="filterUser(searchField.value)" #searchField />
              </mat-form-field>

              <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded fuse-mat-dense w-full" [floatLabel]="'always'">
                <mat-select
                  name="selectedDepartment"
                  [(ngModel)]="selectedDepartment"
                  [ngModelOptions]="{ standalone: true }"
                  multiple
                  (ngModelChange)="searchCustomByValue($event)"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      ngModel
                      [placeholderLabel]="'Search Department'"
                      (ngModelChange)="filterTags($event, 'department')"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let option of filterDepartmentOption" [value]="option._id">
                    {{ option?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-auto overflow-y-auto">
            <ng-container>
              <ng-container *ngFor="let user of userList">
                <div
                  class="z-20 flex items-center py-5 px-8 cursor-pointer border-b"
                  [ngClass]="{
                    'hover:bg-gray-100 dark:hover:bg-hover': !selectedUser || selectedUser.userId !== user?.userId,
                    'bg-primary-50 dark:bg-hover': selectedUser && selectedUser.userId === user?.userId
                  }"
                  (click)="changeUser(user)"
                >
                  <div class="relative flex flex-0 items-center justify-center w-10 h-10">
                    <ng-container *ngIf="user?.avatar">
                      <img class="w-full h-full rounded-full object-cover" [src]="user?.avatar" alt="Contact avatar" />
                    </ng-container>
                    <ng-container *ngIf="!user?.avatar">
                      <div
                        class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                      >
                        {{ user?.firstName.charAt(0) }} {{ user?.lastName.charAt(0) }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="min-w-0 ml-4">
                    <div class="font-medium leading-5 truncate">{{ user?.firstName }} {{ user?.lastName }}</div>
                    <div class="leading-5 truncate text-secondary" [class.text-primary]="1 > 0" [class.dark:text-primary-500]="1 > 0">
                      {{ user?.email }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <div class="flex-auto overflow-y-auto  min-h-full w-full">
          <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
      </ng-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<div class="no-users-found" *ngIf="!userList || userList.length == 0">
  You don’t have any team Members to display.
</div>
