import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { UserProfileComponent } from 'app/shared/components';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SupabaseService } from 'app/core/auth/supabase.service';
// import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar = true;
  user: User;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  detailsFields: any;
  currentUser: any;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _userService: UserService,
    private matDialog: MatDialog,
    private _supabaseService: SupabaseService,
    private _cookieService: CookieService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._supabaseService.getCurrentUser$.pipe().subscribe((user: any) => {
      this.currentUser = user;
    });
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  openDetailsDialog(): void {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '40%',
      data: {
        currentUser: this.currentUser,
      },
      panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
    };
    const dialogRef: MatDialogRef<UserProfileComponent> = this.matDialog.open(UserProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {});
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  // moveToNewApp() {
  //   this._cookieService.set(environment?.TOKEN_KEY, localStorage.getItem(environment?.TOKEN_KEY));
  //   this._cookieService.set(environment?.TOKEN_KEY, localStorage.getItem(environment?.TOKEN_KEY), 5, '/', '.visions.team');
  //   window.open(environment.APP_URL);
  // }
}
