import { Component, Inject, OnInit, ChangeDetectorRef, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { SupabaseService } from 'app/core/auth/supabase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
    @Inject(MatSnackBar) public _matSnackBar: MatSnackBar,
    @Inject(NgxSpinnerService) public _ngxSpinnerService: NgxSpinnerService,
    @Inject(NavigationService) public _navigationService: NavigationService,
    @Inject(SupabaseService) public _supabaseService: SupabaseService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._ngxSpinnerService.show();
    setTimeout(() => {
      if (this._supabaseService.session) {
        this._supabaseService.setCurrentUser().subscribe(
          () => {
            this._navigationService.get().subscribe(
              () => {
                this._ngxSpinnerService.hide();
              },
              (err) => {
                console.log(err);
                this._ngxSpinnerService.hide();
              }
            );
          },
          (err) => {
            console.log(err);
            if (err.error.message !== undefined && err.error.message !== null) {
              this._matSnackBar.open(`${err.error.message}`, 'Error', {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                panelClass: ['error-snackbar'],
              });
            }
            this._router.navigate(['sign-in']);
            this._ngxSpinnerService.hide();
          }
        );
      } else {
        this._ngxSpinnerService.hide();
      }
    }, 1000);
  }

  goToLogin() {
    this._router.navigate(['sign-in']);
  }
}
