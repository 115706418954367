<div class="headers ">
  <div class="title">Notification</div>
  <button class="closebtn" (click)="onCloseClick()">
    <svg class="" color="#FFFFFF" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78571 8.78572C10.0714 9.07143 10.0714 9.5 9.78571 9.78571C9.64286 9.92857 9.5 10 9.28571 10C9.07143 10 8.92857 9.92857 8.78572 9.78571L5 6L1.21429 9.78571C1.07143 9.92857 0.928571 10 0.714286 10C0.5 10 0.357143 9.92857 0.214286 9.78571C-0.0714286 9.5 -0.0714286 9.07143 0.214286 8.78572L4 5L0.214286 1.21429C-0.0714286 0.928571 -0.0714286 0.5 0.214286 0.214286C0.5 -0.0714286 0.928571 -0.0714286 1.21429 0.214286L5 4L8.78572 0.214286C9.07143 -0.0714286 9.5 -0.0714286 9.78571 0.214286C10.0714 0.5 10.0714 0.928571 9.78571 1.21429L6 5L9.78571 8.78572Z"
        fill="white"
      />
    </svg>
  </button>
</div>
<div class="models">
  <mat-tab-group (selectedTabChange)="tabClick($event)">
    <mat-tab label="All">
      <ng-container *ngTemplateOutlet="tabUI"></ng-container>
    </mat-tab>
    <mat-tab label="Unread">
      <ng-container *ngTemplateOutlet="tabUI"></ng-container>
    </mat-tab>
    <mat-tab label="Read">
      <ng-container *ngTemplateOutlet="tabUI"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>
<ng-template #tabUI>
  <ng-container *ngFor="let notification of recordsList; let i = index">
    <div class="flex pt-2 pl-4 pointer" (click)="navigate(notification)">
            <div [ngStyle]="getBackgroundStyle(i)" class="flex flex-col p-2 notificationBox">
                <div class="line-clamp-1">
                  <span class="font-semibold">{{ notification.message }}</span>
                </div>
                <div class="line-clamp-1">Leave Type: - {{ notification.absenceType }}</div>
                <div class="line-clamp-1">
                  Date Requested:
                  {{ notification.startDate | date: 'medium' }}
                  |
                  {{ notification.endDisplayDate | date: 'medium' }}
                </div>
              <div class="mt-2 ml-auto text-sm leading-none text-secondary">
                {{ notification.createdAt | date: 'medium' }}
              </div>
          </div>
    </div>
  </ng-container>
</ng-template>

<div class="footer-paginator">
  <mat-paginator
    class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    #paginator
    class="demo-paginator"
    (page)="pageChanged($event)"
    [length]="totalCount"
    [pageSize]="recordsFilter.limit"
    [disabled]="disabled"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
    [hidePageSize]="hidePageSize"
    [pageIndex]="pageIndex"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
