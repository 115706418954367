import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';

//Base service
import { BaseApiService } from '../../../../shared/services/API-service/base-service';

@Injectable({ providedIn: 'root' })
export class BranchService extends BaseApiService {
  private _branches: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private _branchesCount: number;
  private _branchesList: any;

  constructor(private _http: HttpClient) {
    super('companies', _http);
  }

  get branches$(): Observable<any[]> {
    return this._branches.asObservable();
  }

  get branchesCount$() {
    return this._branchesCount;
  }

  get branchesList$() {
    return this._branchesList;
  }

  getAll(filter: any): Observable<any[]> {
    return this.post(`branch-listing`, filter).pipe(
      tap((response) => {
        const result = response.result;
        this._branchesCount = response.count;
        this._branches.next(result);
      })
    );
  }

  create(branch: any) {
    return this.post(`create-branch`, branch).pipe(
      map((newBranch) => {
        return newBranch;
      })
    );
  }

  update(id: string, branch: any) {
    return this.post(`branch-update`, branch).pipe(
      map((updatedBranch) => {
        return updatedBranch;
      })
    );
  }

  linkBranch(branch: any) {
    return this.post(`branch-linking-create`, { branchDetails: branch }).pipe(
      map((linkBranch) => {
        return linkBranch;
      })
    );
  }

  unLinkBranch(branchId): Observable<any[]> {
    return this.get(`branch-un-link`, { id: branchId }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  LinkedList(filter: any): Observable<any[]> {
    return this.get(`branch-linked-list`, { id: filter.sourceId }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  changeStatus(id: string, status: any): Observable<any> {
    return this.branches$.pipe(
      take(1),
      switchMap((branches) =>
        this.post(`update-status`, { branchId: id, action: status }).pipe(
          map((updatedBranch) => {
            // Find the index of the updated branch
            const index = branches.findIndex((item) => item._id === id);

            // Update the branch
            branches[index].status = status;

            // Update the branch's
            this._branches.next(branches);

            // Return the updated branch
            return updatedBranch;
          })
        )
      )
    );
  }

  getRecord(id: any): Observable<any> {
    return this.get('branch-detail', { id }).pipe(
      tap((response) => {
        return response;
      })
    );
  }
}
