import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

//Base service
import { BaseApiService } from '../../../../shared/services/API-service/base-service';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseApiService {
  private _companies: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private _companiesCount: number;
  private _companiesList: any;

  constructor(private _http: HttpClient) {
    super('companies', _http);
  }

  get companies$(): Observable<any[]> {
    return this._companies.asObservable();
  }

  get companiesCount$() {
    return this._companiesCount;
  }

  get companiesList$() {
    return this._companiesList;
  }

  getAll(filter: any): Observable<any[]> {
    return this.post(`listing`, filter).pipe(
      tap((response) => {
        const result = response.result;
        this._companiesCount = response.count;
        this._companies.next(result);
      })
    );
  }

  create(company: any) {
    return this.post(`create`, company).pipe(
      map((newCompany) => {
        return newCompany;
      })
    );
  }

  update(id: string, company: any) {
    return this.post(`update`, company).pipe(
      map((updatedCompany) => {
        return updatedCompany;
      })
    );
  }

  changeStatus(id: string, status: any): Observable<any> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies) =>
        this.post(`update-status`, { companyId: id, action: status }).pipe(
          map((updatedCompany) => {
            // Find the index of the updated company
            const index = companies.findIndex((item) => item._id === id);

            // Update the company
            companies[index].status = status;

            // Update the company's
            this._companies.next(companies);

            // Return the updated company
            return updatedCompany;
          })
        )
      )
    );
  }

  getRecord(id: any): Observable<any> {
    return this.get('detail', { id }).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getCompaniesList(): Observable<any> {
    return this.get(`filter-list`).pipe(
      tap((response) => {
        this._companiesList = response;
        return response;
      })
    );
  }
}
