<div class="search new-search flex flex-row">
  <div class="input-feild">
    <input type="text" (ngModelChange)="onChange($event)" (keyup.enter)="searchItem(searchString)"
      [(ngModel)]="searchString" [autocomplete]="'off'" [placeholder]="'Search'">
  </div>

  <div class="flex flex-row line">
    <svg width="1" height="38" viewBox="0 0 1 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="-2.18557e-08" x2="0.500002" y2="38" stroke="#80B93C" />
    </svg>
    <div class="searchIcon" style="margin-left: 3px;
    margin-top: 5px;">
      <mat-icon (click)="onSearch(searchString,searchActive)" class="ml-2" class="cursor-pointer">
        {{ searchActive == true ? 'search': 'close'}}
      </mat-icon>
    </div>
  </div>
</div>