import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SupabaseService } from 'app/core/auth/supabase.service';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    SupabaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
