import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap, map } from 'rxjs';

//Base service
import { BaseApiService } from './base-service';

@Injectable({ providedIn: 'root' })
export class TenantMasterService extends BaseApiService {
  // Observers
  private _departmentList: any;
  private _employmentTypeList: any;
  private _taxList: any;
  private _positionList: any;
  private _costCenterList: any;
  private _healthInsurancesList: any;
  private _paymentTypeList: any;
  private _currencyList: any;
  private _roleList: any;
  private _userList: any;
  private _financialYears: any;
  private _countryList: any;
  private _languageList: any;
  private _leadStatusList: any;
  private _companyTypeList: any;
  private _leadCompanyTypeList: any;
  private _contactRoleList: any;
  private _spokenLanguageList: any;
  private _accountSourceList: any;
  private _jobTitleList: any;
  private _tenantDepartmentList: any;
  private _productInterestList: any;
  private _businessTerritoryList: any;
  private _cityList: any;
  private _stateList: any;
  private _businessSubUnit: any;
  private _supervisorsList: any;
  private _officeLocation: any;
  private _userProfile: any;
  private _branchFilter: any;

  constructor(private _http: HttpClient) {
    super('tenant', _http);
  }

  get departmentList$() {
    return this._departmentList;
  }

  get employmentTypeList$() {
    return this._employmentTypeList;
  }

  get positionList$() {
    return this._positionList;
  }

  get costCenterList$() {
    return this._costCenterList;
  }

  get taxList$() {
    return this._taxList;
  }

  get roleList$() {
    return this._roleList;
  }

  get userList$() {
    return this._userList;
  }

  get financialYearsList$() {
    return this._financialYears;
  }

  get healthInsurancesList$() {
    return this._healthInsurancesList;
  }

  get paymentTypeList$() {
    return this._paymentTypeList;
  }

  get currencyList$() {
    return this._currencyList;
  }

  get countryList$() {
    return this._countryList;
  }

  get languageList$() {
    return this._languageList;
  }

  get leadStatusList$() {
    return this._leadStatusList;
  }

  get companyTypeList$() {
    return this._companyTypeList;
  }

  get officeLocation$() {
    return this._officeLocation;
  }

  get userProfile$() {
    return this._userProfile;
  }

  get branchFilter$() {
    return this._branchFilter;
  }

  get leadCompanyTypeList$() {
    return this._leadCompanyTypeList;
  }

  get contactRoleList$() {
    return this._contactRoleList;
  }

  get spokenLanguageList$() {
    return this._spokenLanguageList;
  }
  get accountSourceList$() {
    return this._accountSourceList;
  }
  get jobTitleList$() {
    return this._jobTitleList;
  }
  get tenantDepartmentList$() {
    return this._tenantDepartmentList;
  }
  get productInterestList$() {
    return this._productInterestList;
  }
  get businessTerritoryList$() {
    return this._businessTerritoryList;
  }
  get cityList$() {
    return this._cityList;
  }

  get stateList$() {
    return this._stateList;
  }

  get businessSubUnitList$() {
    return this._businessSubUnit;
  }
  get supervisorsList$() {
    return this._supervisorsList;
  }

  masterList(filter: any): Observable<any> {
    return this.post('masters', filter).pipe(
      tap((response) => {
        // Department
        if (response?.departments) {
          this._departmentList = response.departments;
        }

        // employetype
        if (response?.employmentTypes) {
          this._employmentTypeList = response.employmentTypes;
        }

        // position
        if (response?.positionMasters) {
          this._positionList = response.positionMasters;
        }

        // cost
        if (response?.costs) {
          this._costCenterList = response.costs;
        }

        // roles
        if (response?.roles) {
          this._roleList = response.roles;
        }

        // users
        if (response?.users) {
          this._userList = response.users;
        }

        // financial years
        if (response?.financialYears) {
          this._financialYears = response.financialYears;
        }

        // health insurance
        if (response?.healthInsurances) {
          this._healthInsurancesList = response.healthInsurances;
        }

        // spoken language
        if (response?.spokenLanguages) {
          this._spokenLanguageList = response.spokenLanguages;
        }

        // payment type
        if (response?.paymentTypes) {
          this._paymentTypeList = response.paymentTypes;
        }

        if (response?.currencies) {
          this._currencyList = response.currencies;
        }

        // tax master
        if (response?.taxMasters) {
          this._taxList = response.taxMasters;
        }

        // country
        if (response?.countries) {
          this._countryList = response.countries;
        }

        // language
        if (response?.language) {
          this._languageList = response.language;
        }

        // lead status
        if (response?.leadStatus) {
          this._leadStatusList = response.leadStatus;
        }

        // company type
        if (response?.companyTypes) {
          this._companyTypeList = response.companyTypes;
        }

        // office Location
        if (response?.officeLocation) {
          this._officeLocation = response.officeLocation;
        }

        // User Profile
        if (response?.userProfile) {
          this._userProfile = response.userProfile;
        }

        // Branch Filter
        if (response?.branchFilter) {
          this._branchFilter = response.branchFilter;
        }

        // company type
        if (response?.tenantCompanyType) {
          this._leadCompanyTypeList = response.tenantCompanyType;
        }

        // language
        if (response?.languages) {
          this._languageList = response.languages;
        }

        // contact role
        if (response?.contactRoles) {
          this._contactRoleList = response.contactRoles;
        }

        //accountSource
        if (response?.accountSource) {
          this._accountSourceList = response.accountSource;
        }
        //jobTitle
        if (response?.jobTitle) {
          this._jobTitleList = response.jobTitle;
        }
        //tenantDepartment
        if (response?.tenantDepartment) {
          this._tenantDepartmentList = response.tenantDepartment;
        }
        //productInterest
        if (response?.productInterest) {
          this._productInterestList = response.productInterest;
        }
        //businessTerritory
        if (response?.businessTerritory) {
          this._businessTerritoryList = response.businessTerritory;
        }
        //city
        if (response?.city) {
          this._cityList = response.city;
        }

        if (response?.state) {
          this._stateList = response.state;
        }

        // business sub unit
        if (response?.subBusinessTerritory) {
          this._businessSubUnit = response.subBusinessTerritory;
        }

        //supervisor
        if (response?.supervisors) {
          this._supervisorsList = response.supervisors;
        }

        return response;
      })
    );
  }

  getDepartmentHierarchy(companyId: any) {
    return this.post(`departments/filter-hierarchy`, { companyId: companyId }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCostCenterByDepartment(companyId: any, departments: any) {
    return this.post(`cost-centres/filter-list`, { companyId: companyId, departmentIds: departments }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
