import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDialogModule } from '@angular/material/dialog';

import { CompanyDetailsComponent, UserProfileComponent, BreadcrumbComponent, BranchDetailsComponent } from './components';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, MatDialogModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, CompanyDetailsComponent, UserProfileComponent, BreadcrumbComponent, BranchDetailsComponent],
  declarations: [CompanyDetailsComponent, UserProfileComponent, BreadcrumbComponent, BranchDetailsComponent],
})
export class SharedModule {}
